import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {Divider, Drawer} from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import PhoneLockedIcon from '@material-ui/icons/PhoneLocked';
import InputIcon from '@material-ui/icons/Input';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import DescriptionIcon from '@material-ui/icons/Description';

import {Profile, SidebarNav} from './components';
import PermissionValidator from "../../../../utils/PermissionValidator";

const useStyles = makeStyles(theme => ({
    drawer: {
        width: 240,
        [theme.breakpoints.up('lg')]: {
            marginTop: 64,
            height: 'calc(100% - 64px)'
        }
    },
    root: {
        backgroundColor: theme.palette.white,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        padding: theme.spacing(2)
    },
    divider: {
        margin: theme.spacing(2, 0)
    },
    nav: {
        marginBottom: theme.spacing(2)
    }
}));

const Sidebar = props => {
    const {open, variant, onClose, className, ...rest} = props;

    const classes = useStyles();

    const pages = [
        {
            title: 'Активные звонки',
            href: '/active-calls',
            icon: <PhoneInTalkIcon/>
        },
        {
            title: 'Статистика звонков',
            href: '/archive-calls',
            icon: <PhoneLockedIcon/>
        },
        {
            title: 'Дашборд',
            href: '/statistics',
            icon: <DashboardIcon />
        },
        {
            title: 'Отчет по операторам',
            href: '/report/operators',
            icon: <DescriptionIcon />
        },
        {
            title: 'Стримеры',
            href: '/streamers',
            icon: <PeopleIcon/>
        },
        {
            title: 'Записи звонков',
            href: '/stream-recordings',
            icon: <OndemandVideoIcon/>
        },
        {
            title: 'Выход',
            href: '/logout',
            icon: <InputIcon/>
        }
        // {
        //   title: 'Settings',
        //   href: '/settings',
        //   icon: <SettingsIcon />
        // }
    ];

    const pagesWithPermission = pages.filter((page) => {
        if (page.href === '/streamers') {
            return PermissionValidator.canViewOperators();
        } else if (page.href === '/stream-recordings') {
            return PermissionValidator.canViewRawRecordings();
        } else if (page.href === '/statistics') {
            return PermissionValidator.canViewReports();
        } else if (page.href === '/report/operators') {
            return PermissionValidator.canViewReports();
        }
        return true;
    });

    return (
        <Drawer
            anchor="left"
            classes={{paper: classes.drawer}}
            onClose={onClose}
            open={open}
            variant={variant}
        >
            <div
                {...rest}
                className={clsx(classes.root, className)}
            >
                <Profile/>
                <Divider className={classes.divider}/>
                <SidebarNav
                    className={classes.nav}
                    pages={pagesWithPermission}
                />
            </div>
        </Drawer>
    );
};

Sidebar.propTypes = {
    className: PropTypes.string,
    onClose: PropTypes.func,
    open: PropTypes.bool.isRequired,
    variant: PropTypes.string.isRequired
};

export default Sidebar;
