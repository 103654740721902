import customAxios from '../../utils/customAxios';
import apiRoutes from '../../apiRoutes';

export const operatorFetchStatus = (operatorId) => {

    return (dispatch) => {

        dispatch({type: 'OPERATOR_FETCH_STATUS_START'});

        customAxios.get(apiRoutes.OPERATORS + `/${operatorId}`)
            .then((response) => {
                dispatch({
                    type: 'OPERATOR_FETCH_STATUS_SUCCESS',
                    payload: response.data.status
                });
            })
            .catch((error) => {
                dispatch({
                    type: 'OPERATOR_FETCH_STATUS_FAIL',
                    payload: 'Ошибка получения статуса оператора'
                });
            });
    }
};

export const operatorChangeStatus = (operatorId, status, callback) => {

    return (dispatch) => {

        dispatch({type: 'OPERATOR_CHANGE_STATUS_START'});

        const apiRoute = apiRoutes.OPERATORS + `/${operatorId}/${status}`;

        customAxios.get(apiRoute)
            .then((response) => {
                dispatch({
                    type: 'OPERATOR_CHANGE_STATUS_SUCCESS',
                    payload: response.data.status
                });
            })
            .catch((error) => {
                dispatch({
                    type: 'OPERATOR_CHANGE_STATUS_FAIL',
                    payload: 'Ошибка изменения статуса оператора'
                });
            })
            .finally(callback);
    }
};