import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Zoom from '@material-ui/core/Zoom';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles, withStyles } from '@material-ui/styles';

import OpenviduHelper from '../../utils/OpenviduHelper';
import { createOVToken, setLoadingOVToken, setOVSession, setOVToken, unsetLoadingOVToken } from '../../actions/openvidu';
import CircularProgress from '@material-ui/core/CircularProgress';
import StartViewVideoSelector from './StartViewVideoSelector';
import StartViewAudioSelector from './StartViewAudioSelector';
import StartViewCounter from './StartViewCounter';
import StartViewName from './StartViewName';
import StartViewSku from './StartViewSku';
import SubmitPopper from '../../utils/SubmitPopper';
import StartViewConsentCheckbox from './StartViewConsentCheckbox';
import beta_logo from '../../images/beta.svg';
import { SnackBarAlert } from '../../utils/SnackBarAlert';
import StartViewConsultationTimeChecker from './StartViewConsultationTimeChecker';
import './StartView.scss';
import { setCallError } from '../../actions/appError';

const useStyles = (theme) => ({
    root: {
        // paddingTop: '20px',
        paddingBottom: '20px',
    },
    grid: {
        height: '100%',
    },
    name: {
        marginTop: theme.spacing(3),
        color: theme.palette.white,
    },
    contentContainer: {},
    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        textAlign: 'center',
        margin: '20px 0px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '20px',
        },
    },
    logoImage: {
        marginLeft: theme.spacing(4),
    },
    contentBody: {
        paddingLeft: '10px',
        paddingRight: '10px',
    },
    form: {
        paddingLeft: 100,
        paddingRight: 100,
        paddingTop: 20,
        paddingBottom: 30,
        flexBasis: 700,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    },
    signInButton: {
        marginTop: '30px',
        paddingTop: '10px',
        paddingBottom: '10px',
        fontSize: '18px',
    },
    notchedOutline: {
        borderWidth: '1px',
        borderColor: 'yellow !important',
    },
});

class StartView extends React.Component {
    constructor(props) {
        super(props);

        this.openviduHelper = new OpenviduHelper();
        this.locationSearch = props.location.search;
        this.urlParams = new URLSearchParams(this.locationSearch);

        this.sku = this.urlParams.get('sku') ? this.urlParams.get('sku') : null;
        this.clientId = this.urlParams.get('client_id') ? this.urlParams.get('client_id') : null;
        this.clientOs = this.urlParams.get('os') && this.urlParams.get('os');

        this.formFields = {
            clientName: { label: "Ваше ім'я" },
            product: { label: 'Товар для консультації' },
            //clientQuestion: {label: 'Причина звернення'}
        };

        this.state = {
            step: 0,
            popper: { anchorEl: null, text: '' },
            snackBarAlert: { open: false, text: '' }
        };
    }

    componentDidMount() {
        if (!this.sku || !this.clientId) {
            window.location.href = 'https://allo.ua';
        } else if (this.props.appErrorState.callError) {
            this.setState({ step: 2 });
        }
    }

    handleFieldChange = (event, field) => {
        event.persist();

        this.setState((prevState) => {
            return { ...prevState, [field]: event.target.value };
        });
    };

    renderSnackBarAlert = (text) => {
        this.setState({ snackBarAlert: { open: true, text: text } });
    };

    renderSnackBarAlertCloseHandler = () => {
        this.setState((prevState) => {
            return { ...prevState, snackBarAlert: { ...prevState.snackBarAlert, open: false } };
        });
    };

    validateForm = () => {
        const errors = [];

        Object.keys(this.formFields).forEach((field) => {
            if (!this.props.clientData[field]) {
                errors.push(`"${this.formFields[field].label}" не заповнено`);
            }
        });

        if (errors.length === 0) {
            return true;
        } else {
            return errors;
        }
    };

    validateMic = () => {
        const { audio } = this.props.mediaDevicesState;
        return audio.permission && audio.selectedDevice;
    };

    validateRecordingConsent = () => {
        return this.props.clientData.recordingConsent;
    };

    handleOpenPopper = (targetEl) => {
        window.setTimeout(() => {
            this.setState((prevState) => {
                return { ...prevState, popper: { ...prevState.popper, anchorEl: targetEl } };
            });
        }, 50);
    };

    handleClosePopper = () => {
        this.setState((prevState) => {
            return { ...prevState, popper: { ...prevState.popper, anchorEl: null } };
        });
    };

    submitHandler = async (e, updateCounter, unsetCallError) => {
        e.preventDefault();
        e.persist();

        const targetEl = e.currentTarget;

        const validateForm = this.validateForm();

        if (validateForm !== true) {
            this.setState(
                (prevState) => {
                    return { ...prevState, popper: { ...prevState.popper, text: validateForm } };
                },
                () => {
                    this.handleOpenPopper(targetEl);
                },
            );

            return;
        }

        if (!this.validateMic()) {
            this.setState(
                (prevState) => {
                    return {
                        ...prevState,
                        popper: { ...prevState.popper, text: 'Необхідно активувати мікрофон' },
                    };
                },
                () => {
                    this.handleOpenPopper(targetEl);
                },
            );
            return;
        }

        if (!this.validateRecordingConsent()) {
            this.setState(
                (prevState) => {
                    return {
                        ...prevState,
                        popper: {
                            ...prevState.popper,
                            text: 'Необхідно дати згоду на запис консультації',
                        },
                    };
                },
                () => {
                    this.handleOpenPopper(targetEl);
                },
            );
            return;
        }

        if (this.props.OVSession.loading) return;

        try {
            this.props.setLoadingOVToken();

            const clientData = {
                clientName: this.props.clientData.clientName,
                customer_id: this.props.clientData.authorizedClient ? this.clientId : null,
                //subject_id: this.props.clientData.clientQuestion,
                product_name: this.props.clientData.product.name,
                sku: this.props.clientData.product.sku,
                product_url: this.props.clientData.product.url,
                client_camera_on: !!this.props.mediaDevicesState.video.selectedDevice,
                previous_session_id: this.fetchPreviousSessionIdFromLocalStorage(),
                os: this.clientOs ?? 'web'
            };

            const tokenObj = await this.openviduHelper.createTokenForClient(clientData);

            this.setPreviousSessionIdToLocalStorage(tokenObj.session);
            this.props.setOVToken(tokenObj.token);
            this.props.setOVSession(tokenObj.session);

            this.setState({ step: 2 });

            if (updateCounter) updateCounter();
            if (unsetCallError) unsetCallError();

            window.Echo.channel(`${tokenObj.session}`).listen('.App\\Events\\OperatorConnected', (e) => {
                console.log('Event OperatorConnected');

                this.props.history.push(`/call/${tokenObj.session}`);
            });
        } catch (e) {
            this.props.unsetLoadingOVToken();
            if (unsetCallError) unsetCallError();
            this.renderSnackBarAlert(e.response.data.error ? e.response.data.error : "Помилка з'єднання з оператором");
        }
    };

    redirectToAllo = () => {
        if (this.props.clientData.product && this.props.clientData.product.url) {
            window.location.href = this.props.clientData.product.url;
        } else {
            window.location.href = 'https://allo.ua';
        }
    };

    fetchPreviousSessionIdFromLocalStorage = () => {
        return localStorage.getItem('previous_session_id');
    };

    setPreviousSessionIdToLocalStorage = (sessionId) => {
        localStorage.setItem('previous_session_id', sessionId);
    };

    render() {
        const { classes } = this.props;

        let buttonText = "З'єднати";

        if (this.props.OVSession.loading) {
            buttonText = (
                <React.Fragment>
                    <div style={{ position: 'relative' }}>
                        <CircularProgress
                            size={25}
                            style={{
                                position: 'absolute',
                                left: '-35px',
                                top: '2px',
                                color: 'white',
                            }}
                        />
                        <span>З'єднати</span>
                    </div>
                </React.Fragment>
            );
        }
        return (
            <div className="container" id="container">
                <div className={classes.root}>
                    <Grid className={classes.grid} container>
                        <Grid item lg={3}></Grid>
                        <Grid className={classes.content} item lg={6} xs={12}>
                            {(this.state.step === 1 || this.state.step === 0) && (
                                <div className={classes.contentBody}>
                                    <Zoom in={true}>
                                        <Paper elevation={10} style={{ position: 'relative' }}>
                                            <img
                                                src={beta_logo}
                                                alt="beta"
                                                style={{
                                                    position: 'absolute',
                                                    top: '10px',
                                                    right: '10px',
                                                    zIndex: 1,
                                                }}
                                            />

                                            <form className={classes.form}>
                                                <Typography className={classes.title} variant="h2">
                                                    Вітаємо Вас в Експерт онлайн
                                                </Typography>

                                                <Grid container>
                                                    {this.state.step === 0 && (
                                                        <>
                                                            <Grid className={classes.content} item xs={12}>
                                                                <StartViewConsultationTimeChecker
                                                                    clientData={this.props.clientData}
                                                                    sku={this.sku}
                                                                    label={this.formFields.product.label}
                                                                    successHandler={() => {
                                                                        this.setState({ step: 1 });
                                                                    }}
                                                                    failHandler={() => {
                                                                        this.redirectToAllo();
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </>
                                                    )}

                                                    {this.state.step === 1 && (
                                                        <React.Fragment>
                                                            <Grid item xs={2} md={false}></Grid>
                                                            <Grid className={classes.content} item xs={10} md={12}>
                                                                <StartViewName
                                                                    clientId={this.clientId}
                                                                    label={this.formFields.clientName.label}
                                                                />
                                                                <StartViewSku
                                                                    sku={this.sku}
                                                                    label={this.formFields.product.label}
                                                                    clientData={this.props.clientData}
                                                                />
                                                                <StartViewAudioSelector />
                                                                <StartViewVideoSelector />
                                                                {/*<div*/}
                                                                {/*    style={{*/}
                                                                {/*        marginTop: '15px',*/}
                                                                {/*        marginBottom: '15px',*/}
                                                                {/*    }}>*/}
                                                                {/*    <a href=" https://allo.ua/promo/open/expert-online" target="_blank">*/}
                                                                {/*        Умови отримання 300 бонусних грн за тест бета-версії*/}
                                                                {/*    </a>*/}
                                                                {/*</div>*/}
                                                                <StartViewConsentCheckbox />
                                                            </Grid>
                                                        </React.Fragment>
                                                    )}
                                                </Grid>

                                                {this.state.step === 1 && (
                                                    <>
                                                        <SubmitPopper
                                                            open={Boolean(this.state.popper.anchorEl)}
                                                            anchorEl={this.state.popper.anchorEl}
                                                            handleClosePopper={this.handleClosePopper}
                                                            message={this.state.popper.text}
                                                        />
                                                        <Button
                                                            className={classes.signInButton}
                                                            color="primary"
                                                            fullWidth
                                                            type="submit"
                                                            variant="contained"
                                                            style={{
                                                                width: '100%',
                                                                height: '50px',
                                                            }}
                                                            onClick={this.submitHandler}
                                                            // disabled={this.props.OVSession.loading}
                                                        >
                                                            {buttonText}
                                                        </Button>
                                                    </>
                                                )}
                                            </form>
                                        </Paper>
                                    </Zoom>
                                </div>
                            )}

                            {this.state.step === 2 && (
                                <div className={classes.contentBody}>
                                    <Zoom in={true}>
                                        <Paper elevation={10} style={{ position: 'relative' }}>
                                            <img
                                                src={beta_logo}
                                                alt="beta"
                                                style={{
                                                    position: 'absolute',
                                                    top: '10px',
                                                    right: '10px',
                                                    zIndex: 1,
                                                }}
                                            />
                                            <div className={this.props.appErrorState.callError ? 'error-form' : classes.form}>
                                                <StartViewCounter
                                                    clientData={this.props.clientData}
                                                    createToken={this.submitHandler}
                                                    loading={this.props.OVSession.loading}
                                                    callError={this.props.appErrorState.callError}
                                                    setCallError={this.props.setCallError}
                                                />
                                            </div>
                                        </Paper>
                                    </Zoom>
                                </div>
                            )}
                        </Grid>
                    </Grid>
                </div>
                <SnackBarAlert
                    open={this.state.snackBarAlert.open}
                    text={this.state.snackBarAlert.text}
                    closeHandler={this.renderSnackBarAlertCloseHandler}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        OVSession: state.OVSession,
        clientData: state.clientData,
        mediaDevicesState: state.mediaDevicesState,
        appErrorState: state.appErrorState,
    };
};

export default connect(mapStateToProps, {
    createOVToken,
    setOVToken,
    setOVSession,
    setLoadingOVToken,
    unsetLoadingOVToken,
    setCallError,
})(withRouter(withStyles(useStyles)(StartView)));
