const API_WEB_BASE = '/api/front';

export default {
    SESSIONS: API_WEB_BASE + '/openvidu/sessions',
    TOKENS: API_WEB_BASE + '/openvidu/tokens',
    RECORDINGS: API_WEB_BASE + '/openvidu/recordings',
    LOGIN: API_WEB_BASE + '/login',
    MR_LOGIN: API_WEB_BASE + '/login/retail',
    OPERATORS: API_WEB_BASE + '/operators',
    CALL_SUBJECTS: API_WEB_BASE + '/callSubjects',
    PRODUCT: API_WEB_BASE + '/info/product',
    CALL_INFO: API_WEB_BASE + '/calls',
    SET_FIREBASE_TOKEN: API_WEB_BASE + '/firebase/set_token',
    CLIENT: API_WEB_BASE + '/info/customer',
    SEND_RECORDING: API_WEB_BASE + '/openvidu/recordings/send',
    NPS: API_WEB_BASE + '/calls/nps',
    REPORTS: API_WEB_BASE + '/reports/daily',
    OPERATORS_REPORT: API_WEB_BASE + '/reports/operatorsCalls',
    CONSULTATION: API_WEB_BASE + '/info/consultation_time'
};
