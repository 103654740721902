import React from 'react';
import {connect} from 'react-redux';
import './ClientRecording.scss';
import InputMask from 'react-input-mask';
import TextField from "@material-ui/core/TextField";
import customAxios from "../../utils/customAxios";
import apiRoutes from "../../apiRoutes";
import Button from "@material-ui/core/Button";
import SubmitPopper from "../../utils/SubmitPopper";
import {withStyles} from "@material-ui/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import Slide from "@material-ui/core/Slide";
import DialogContent from "@material-ui/core/DialogContent";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = theme => ({
    // root: {
    //     '& .MuiDialog-paper': {
    //         margin: '5px'
    //     }
    // },
    textField: {
        '& .MuiInputBase-root': {fontSize: '18px'}
    },
    title: {
        // textAlign: 'center',
        padding: '25px 25px 10px 25px',
        // '& .MuiTypography-root.MuiTypography-h6': {
        //     fontSize: '18px'
        // }
    },
    closeButton: {
        position: 'absolute',
        right: '5px',
        top: '5px',
        color: theme.palette.grey[500],
    },
    submitButton: {
        marginTop: '30px',
        marginBottom: '10px',
        paddingTop: '10px',
        paddingBottom: '10px',
        fontSize: '16px'
    },
});

const DialogTitle = withStyles(useStyles)((props) => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

class ClientRecording extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dialogOpen: false,
            clientPhone: '',
            loading: false,
            error: null,
            popper: {anchorEl: null, text: ""},
            submitLoading: false,
            submitSuccess: null,
            submitError: null
        };

        this.fetchCall = this.fetchCall.bind(this);
        this.sendRecording = this.sendRecording.bind(this);
        this.validatePhone = this.validatePhone.bind(this);
        this.handleOpenPopper = this.handleOpenPopper.bind(this);
        this.handleClosePopper = this.handleClosePopper.bind(this);
        this.renderButtonText = this.renderButtonText.bind(this);
        this.renderDialogBody = this.renderDialogBody.bind(this);
    }

    componentDidMount() {

        window.setTimeout(() => {
            this.handleDialogOpen()
        }, 1200);

        const {clientData, OVSession} = this.props;

        if (clientData.authorizedClient && OVSession.sessionId) {
            this.fetchCall(OVSession.sessionId);
        }
    }

    fetchCall(sessionId) {
        this.setState({loading: true});

        customAxios.get(apiRoutes.CALL_INFO + `/${sessionId}/client`)
            .then((res) => {

                console.log(res.data);

                this.setState({
                    clientPhone: res.data.data.customer.phone,
                    loading: false
                });
            })
            .catch((error) => {
                this.setState({loading: false});
            });
    }

    validatePhone(normalizedPhoneNumber) {
        if (normalizedPhoneNumber === '') {
            return 'Заповните номер телефону';
        } else {
            if (/^\+?([0-9]{10}|[0-9]{12})$/.test(normalizedPhoneNumber)) {
                return true;
            } else {
                return 'Невірний номер телефону';
            }
        }
    }

    static normalizePhoneNumber(phoneNumber) {

        let phone = phoneNumber;

        if (typeof phoneNumber === 'number') {
            phone = phoneNumber.toString();
        }
        return phone
            .replace(/\s/g, '')
            .replace(/\(/g, '')
            .replace(/\)/g, '');
    }

    sendRecording(e) {

        if(this.state.submitLoading) return;

        e.preventDefault();
        e.persist();

        const targetEl = e.currentTarget;

        const normalizedPhoneNumber = ClientRecording.normalizePhoneNumber(this.state.clientPhone);

        const validated = this.validatePhone(normalizedPhoneNumber);

        if (validated !== true) {

            this.setState((prevState) => {
                return {...prevState, popper: {...prevState.popper, text: validated}};
            }, () => {
                this.handleOpenPopper(targetEl);
            });
            return;
        }

        this.setState({submitLoading: true});

        customAxios.post(apiRoutes.SEND_RECORDING, {
            session_id: this.props.OVSession.sessionId,
            phone: normalizedPhoneNumber
        })
            .then((res) => {

                console.log(res.data);

                this.setState({
                    submitSuccess: 'Запис буде віправлений на вказаний номер телефону на протязі декількох хвилин',
                    submitLoading: false
                }, () => {window.setTimeout(() => {this.handleDialogClose()}, 2000)});
            })
            .catch((error) => {
                this.setState({submitLoading: false, submitError: 'Помилка відправки запису'},
                    () => {window.setTimeout(() => {this.handleDialogClose()}, 2000)});
            });
    }

    handleOpenPopper(targetEl) {

        window.setTimeout(() => {
            this.setState((prevState) => {
                return {...prevState, popper: {...prevState.popper, anchorEl: targetEl}};
            });
        }, 50);
    }

    handleClosePopper() {
        this.setState((prevState) => {
            return {...prevState, popper: {...prevState.popper, anchorEl: null}};
        });
    };

    handleDialogOpen = () => {
        this.setState({dialogOpen: true});
    };

    handleDialogClose = () => {
        this.setState({dialogOpen: false});
    };

    renderButtonText() {
        let buttonText = 'Відправити';

        if (this.state.submitLoading) {
            buttonText = (
                <React.Fragment>
                    <div style={{position: 'relative'}}>
                        <CircularProgress size={25}
                                          style={
                                              {
                                                  position: 'absolute',
                                                  left: '-35px',
                                                  color: 'white'
                                              }
                                          }/>
                        <span>
                            Відправити
                        </span>
                    </div>
                </React.Fragment>
            );
        }
        return buttonText;
    }

    renderDialogBody(){
        if(this.state.submitError){
            return (
                <div style={{marginBottom: '20px'}}>
                    {this.state.submitError}
                </div>
            );
        } else if(this.state.submitSuccess) {
            return (
                <div style={{marginBottom: '20px'}}>
                    {this.state.submitSuccess}
                </div>
            );
        } else {
            return (
                <div style={{textAlign: 'center', marginBottom: '20px'}}>
                    <InputMask
                        mask="+38 (999) 999 99 99"
                        alwaysShowMask={true}
                        value={this.state.clientPhone}
                        onChange={(e) => {
                            this.setState({clientPhone: e.target.value})
                        }}
                    >
                        {() => <TextField
                            name='clientPhone'
                            className={this.props.classes.textField}
                            margin="normal"
                            type="text"
                            fullWidth
                        />}
                    </InputMask>
                    <SubmitPopper
                        open={Boolean(this.state.popper.anchorEl)}
                        anchorEl={this.state.popper.anchorEl}
                        handleClosePopper={this.handleClosePopper}
                        message={this.state.popper.text}
                    />
                    <Button
                        className={this.props.classes.submitButton}
                        fullWidth
                        color="primary"
                        type="submit"
                        variant="contained"
                        onClick={this.sendRecording}
                    >
                        {this.renderButtonText()}
                    </Button>
                </div>
            );
        }
    }


    render() {

        return (
            <Dialog
                TransitionComponent={Transition}
                fullWidth={true}
                maxWidth='xs'
                open={this.state.dialogOpen}
                onClose={this.handleDialogClose}
            >
                <DialogTitle className={this.props.classes.title} onClose={this.handleDialogClose}>
                    Бажаєте отримати запис консультації?
                </DialogTitle>

                <DialogContent>
                    {this.renderDialogBody()}
                </DialogContent>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        OVSession: state.OVSession,
        clientData: state.clientData
    };
};

export default connect(mapStateToProps, {})(withStyles(useStyles)(ClientRecording));