import React, { Component } from 'react';
import { connect } from 'react-redux';

import './VideoRoomComponent.scss';
import { OpenVidu } from 'openvidu-browser';
import StreamComponent from './stream/StreamComponent';
import DialogExtensionComponent from './dialog-extension/DialogExtension';
import ChatComponent from './chat/ChatComponent';
import OpenViduLayout from '../layout/openvidu-layout';
import UserModel from '../models/user-model';
import ToolbarComponent from './toolbar/ToolbarComponent';
import OpenviduHelper from '../utils/OpenviduHelper';
import { setLoadingOVToken, setOVToken, unsetLoadingOVToken } from '../actions/openvidu';
import customAxios from '../utils/customAxios';
import apiRoutes from '../apiRoutes';
import { setCallError } from '../actions/appError';
import FullPageSpinner from '../utils/FullPageSpinner';

class VideoRoomComponent extends Component {
  constructor(props) {
    super(props);
    this.openviduHelper = new OpenviduHelper();
    this.layout = new OpenViduLayout();
    this.hasBeenUpdated = false;
    this.publisher = null;
    let sessionName = this.props.match.params.id ? this.props.match.params.id : 'AlloChatSession_1';
    let userName =
      this.props.auth.user && this.props.auth.user.operator
        ? this.props.auth.user.display_name
        : this.props.clientData.clientName
        ? this.props.clientData.clientName
        : 'User_' + Math.floor(Math.random() * 100);

    this.state = {
      mySessionId: sessionName,
      myUserName: userName,
      session: undefined,
      localUser: {
        connectionId: '',
        audioActive: true,
        videoActive: true,
        screenShareActive: false,
        nickname: '',
        streamManager: null,
        type: 'local',
      },
      subscribers: [],
      chatDisplay: 'none',
    };

    this.joinSession = this.joinSession.bind(this);
    this.leaveSession = this.leaveSession.bind(this);
    this.onbeforeunload = this.onbeforeunload.bind(this);
    this.updateLayout = this.updateLayout.bind(this);
    this.camStatusChanged = this.camStatusChanged.bind(this);
    this.micStatusChanged = this.micStatusChanged.bind(this);
    this.nicknameChanged = this.nicknameChanged.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
    this.screenShare = this.screenShare.bind(this);
    this.stopScreenShare = this.stopScreenShare.bind(this);
    this.closeDialogExtension = this.closeDialogExtension.bind(this);
    this.toggleChat = this.toggleChat.bind(this);
    this.checkNotification = this.checkNotification.bind(this);
    this.checkSize = this.checkSize.bind(this);

    this.isOperator = this.isOperator.bind(this);
    this.getDevices = this.getDevices.bind(this);
    this.getVideoDevices = this.getVideoDevices.bind(this);
    this.switchCamera = this.switchCamera.bind(this);
    this.sendCameraOnToApi = this.sendCameraOnToApi.bind(this);

    this.renderLocalStream = this.renderLocalStream.bind(this);
    this.setLocalUserToState = this.setLocalUserToState.bind(this);
    this.subscribeToConnectionCreated = this.subscribeToConnectionCreated.bind(this);
    this.listenToOperatorLeftEvent = this.listenToOperatorLeftEvent.bind(this);
    this.callErrorHandler = this.callErrorHandler.bind(this);
  }

  componentDidMount() {
    // if (!this.isOperator()) {
    //     setTimeout(() => {this.callErrorHandler();}, 10000);
    // }

    const openViduLayoutOptions = {
      maxRatio: 3 / 2, // The narrowest ratio that will be used (default 2x3)
      minRatio: 9 / 16, // The widest ratio that will be used (default 16x9)
      fixedRatio: false, // If this is true then the aspect ratio of the video is maintained and minRatio and maxRatio are ignored (default false)
      bigClass: 'OV_big', // The class to add to elements that should be sized bigger
      bigPercentage: 0.8, // The maximum percentage of space the big ones should take up
      bigFixedRatio: false, // fixedRatio for the big ones
      bigMaxRatio: 3 / 2, // The narrowest ratio to use for the big elements (default 2x3)
      bigMinRatio: 9 / 16, // The widest ratio to use for the big elements (default 16x9)
      bigFirst: true, // Whether to place the big one in the top left (true) or bottom right
      animate: true, // Whether you want to animate the transitions
    };

    this.layout.initLayoutContainer(document.getElementById('layout'), openViduLayoutOptions);
    window.addEventListener('beforeunload', this.onbeforeunload);
    window.addEventListener('resize', this.updateLayout);
    window.addEventListener('resize', this.checkSize);
    this.joinSession();
    this.listenToOperatorLeftEvent();
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.onbeforeunload);
    window.removeEventListener('resize', this.updateLayout);
    window.removeEventListener('resize', this.checkSize);
    this.leaveSession();

    if (!this.isOperator()) {
      window.Echo.leave(this.state.mySessionId);
    }
  }

  onbeforeunload(event) {
    this.leaveSession();
  }

  joinSession() {
    this.OV = new OpenVidu();

    this.setState(
      {
        session: this.OV.initSession(),
      },
      () => {
        this.subscribeToStreamCreated();
        this.subscribeToConnectionCreated();
        this.connectToSession();
      },
    );
  }

  connectToSession() {
    const ovTokenFromState = this.props.OVSession.token;

    if (ovTokenFromState) {
      console.log('token received: ', ovTokenFromState);
      this.connect(ovTokenFromState);
    } else {
      this.props.setLoadingOVToken();
      this.openviduHelper
        .createTokenWithSessionId(this.state.mySessionId)
        .then((res) => {
          this.props.setOVToken(res.token);
          this.connect(res.token);
        })
        .catch((error) => {
          this.callErrorHandler();
        })
        .finally(() => {
          this.props.unsetLoadingOVToken();
        });
    }
  }

  connect(token) {
    this.state.session
      .connect(token, { clientData: this.state.myUserName })
      .then(() => {
        this.connectWebCam();
      })
      .catch((error) => {
        if (this.props.error) {
          this.props.error({
            error: error.error,
            message: error.message,
            code: error.code,
            status: error.status,
          });
        }
        // alert('There was an error connecting to the session:', error.message);

        console.log('There was an error connecting to the session:', error.code, error.message);
        this.callErrorHandler();
      });
  }

  async connectWebCam() {
    let publisher;

    if (this.isOperator()) {
      publisher = this.OV.initPublisher(undefined, {
        audioSource: undefined,
        videoSource: undefined,
        publishAudio: true,
        publishVideo: true,
        resolution: '1280x720',
        frameRate: 60,
        insertMode: 'APPEND',
        filter: {
          type: 'GStreamerFilter',
          options: {
            command: 'gdkpixbufoverlay location=/opt/openvidu/layouts/images/overlay.png alpha=0.1',
          },
        },
        mirror: false,
      });
    } else {
      publisher = this.OV.initPublisher(undefined, {
        audioSource: undefined,
        videoSource: this.props.mediaDevicesState.video.selectedDevice
          ? this.props.mediaDevicesState.video.selectedDevice
          : false,
        publishAudio: this.state.localUser.audioActive,
        publishVideo:
          this.state.localUser.videoActive && this.props.mediaDevicesState.video.selectedDevice,
        resolution: '1280x720',
        frameRate: 60,
        insertMode: 'APPEND',
        mirror: false,
      });
    }

    this.publisher = publisher;

    if (this.state.session.capabilities.publish) {
      this.state.session.publish(publisher).then(() => {
        if (this.props.joinSession) {
          this.props.joinSession();
        }
      });
    }

    const userToSet = {
      connectionId: this.state.session.connection.connectionId,
      videoActive: this.isOperator() ? true : !!this.props.mediaDevicesState.video.selectedDevice,
      nickname: this.state.myUserName,
      streamManager: publisher,
    };

    // this.sendSignalUserChanged({isScreenShareActive: localUser.isScreenShareActive()});

    this.setState(
      (prevState) => {
        return {
          ...prevState,
          localUser: { ...prevState.localUser, ...userToSet },
        };
      },
      () => {
        this.subscribeToUserChanged();
        this.subscribeToStreamDestroyed();

        // if (!this.isOperator()) {
        //     window.setTimeout(() => {
        //             this.state.localUser.streamManager.publishVideo(true);
        //             window.setTimeout(() => {
        //                     this.state.localUser.streamManager.publishVideo(false);
        //                 },
        //                 500)
        //         },
        //         1000);
        // }

        this.state.localUser.streamManager.on('streamPlaying', (e) => {
          this.updateLayout();
          publisher.videos[0].video.parentElement.classList.remove('custom-class');
        });
      },
    );
  }

  leaveSession() {
    const mySession = this.state.session;
    const sessionid = this.state.mySessionId;

    if (mySession) {
      mySession.disconnect();
    }

    // Empty all properties...
    this.OV = null;

    this.setState({
      session: undefined,
      subscribers: [],
      mySessionId: 'SessionA',
      myUserName: 'OpenVidu_User' + Math.floor(Math.random() * 100),
      localUser: null,
    });

    this.openviduHelper.leaveSession(sessionid);

    // if (this.props.leaveSession) {
    //     this.props.leaveSession();
    // }
  }

  setLocalUserToState(field, callback) {
    this.setState(
      (prevState) => {
        return {
          ...prevState,
          localUser: {
            ...prevState.localUser,
            [field]: !prevState.localUser[field],
          },
        };
      },
      () => {
        if (callback) {
          callback();
        }
      },
    );
  }

  camStatusChanged() {
    console.log('+++++++++PUBLISHER+++++++++');
    console.log(this.publisher);

    if (!this.isOperator() && !this.publisher.properties.videoSource) {
      this.OV.getDevices().then((devices) => {
        const videoDevices = devices.filter((device) => device.kind === 'videoinput');

        // Creating a new publisher with specific videoSource
        // In mobile devices the default and first camera is the front one
        const newPublisher = this.OV.initPublisher(undefined, {
          // videoSource: isFrontCamera ? videoDevices[1].deviceId : videoDevices[0].deviceId,
          audioSource: undefined,
          videoSource: videoDevices[0] ? videoDevices[0].deviceId : false,
          publishAudio: true,
          publishVideo: true,
          resolution: '1280x720',
          frameRate: 60,
          insertMode: 'APPEND',
          mirror: false,
        });

        // Unpublishing the old publisher
        this.OV.session.unpublish(this.publisher);

        // Assigning the new publisher to our global variable 'publisher'
        this.publisher = newPublisher;

        // Publishing the new publisher
        this.OV.session.publish(this.publisher);

        this.setState(
          (prevState) => {
            return {
              ...prevState,
              localUser: {
                ...prevState.localUser,
                streamManager: this.publisher,
                videoActive: true,
              },
            };
          },
          () => {
            // this.subscribeToUserChanged();
            // this.subscribeToStreamDestroyed();

            this.state.localUser.streamManager.on('streamPlaying', (e) => {
              this.updateLayout();
              this.publisher.videos[0].video.parentElement.classList.remove('custom-class');
            });
            this.state.localUser.streamManager.publishVideo(this.state.localUser.videoActive);
            this.sendSignalUserChanged({
              isVideoActive: this.state.localUser.videoActive,
            });
            this.updateLayout();
            this.sendCameraOnToApi();
          },
        );
      });
    } else {
      this.setLocalUserToState('videoActive', () => {
        this.state.localUser.streamManager.publishVideo(this.state.localUser.videoActive);
        this.sendSignalUserChanged({ isVideoActive: this.state.localUser.videoActive });
        this.updateLayout();
      });
    }
  }

  sendCameraOnToApi() {
    customAxios
      .get(apiRoutes.CALL_INFO + `/${this.state.mySessionId}/cameraOn`)
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  micStatusChanged() {
    this.setLocalUserToState('audioActive', () => {
      this.state.localUser.streamManager.publishAudio(this.state.localUser.audioActive);
      this.sendSignalUserChanged({ isAudioActive: this.state.localUser.audioActive });
    });
  }

  nicknameChanged(nickname) {}

  deleteSubscriber(stream) {
    const remoteUsers = this.state.subscribers;
    const userStream = remoteUsers.filter((user) => user.getStreamManager().stream === stream)[0];
    let index = remoteUsers.indexOf(userStream, 0);
    if (index > -1) {
      remoteUsers.splice(index, 1);
      this.setState({
        subscribers: remoteUsers,
      });
    }
  }

  getNickname(streamConnection) {
    if (streamConnection.data && streamConnection.data.includes('%')) {
      const nicknameJsonObj = streamConnection.data.split('%')[0];
      console.log(nicknameJsonObj);
      const nickNameObj = JSON.parse(nicknameJsonObj);
      console.log(nickNameObj);
      const nicknameString = nickNameObj.clientData;
      console.log(nicknameString);

      return nicknameString;
    } else if (
      streamConnection.options &&
      streamConnection.options.metadata &&
      streamConnection.options.metadata.includes('%')
    ) {
      const nicknameJsonObj = streamConnection.options.metadata.split('%')[0];
      console.log(nicknameJsonObj);
      const nickNameObj = JSON.parse(nicknameJsonObj);
      console.log(nickNameObj);
      const nicknameString = nickNameObj.clientData;
      console.log(nicknameString);

      return nicknameString;
    }
    return null;
  }

  subscribeToStreamCreated() {
    this.state.session.on('streamCreated', (event) => {
      console.log('JJJJJJJJJJJJJJJJJJJJJJJJJJJJJJ');
      console.log(event.stream.connection);
      console.log('JJJJJJJJJJJJJJJJJJJJJJJJJJJJJJ');

      const nickname = this.getNickname(event.stream.connection);

      if (nickname === null) return;

      const subscriber = this.state.session.subscribe(event.stream, undefined);
      let subscribers = this.state.subscribers;
      subscriber.on('streamPlaying', (e) => {
        this.checkSomeoneShareScreen();
        subscriber.videos[0].video.parentElement.classList.remove('custom-class');
      });
      const newUser = new UserModel();
      newUser.setStreamManager(subscriber);
      newUser.setConnectionId(event.stream.connection.connectionId);
      newUser.setType('remote');

      // const nickname = this.getNickname(event.stream.connection);
      //
      // if (nickname === null) return;

      newUser.setNickname(nickname);

      subscribers.push(newUser);

      this.setState(
        {
          subscribers: subscribers,
        },
        () => {
          if (this.state.localUser) {
            this.sendSignalUserChanged({
              isAudioActive: this.state.localUser.audioActive,
              isVideoActive: this.state.localUser.videoActive,
              nickname: this.state.localUser.nickname,
              isScreenShareActive: this.state.localUser.screenShareActive,
            });
          }
          this.updateLayout();
        },
      );

      // if (nickname) {
      //
      //     newUser.setNickname(JSON.parse(nickname).clientData);
      //
      //     console.log(JSON.parse(nickname).clientData);
      //
      //     subscribers.push(newUser);
      //
      //     this.setState(
      //         {
      //             subscribers: subscribers,
      //         },
      //         () => {
      //             if (this.state.localUser) {
      //                 this.sendSignalUserChanged({
      //                     isAudioActive: this.state.localUser.audioActive,
      //                     isVideoActive: this.state.localUser.videoActive,
      //                     nickname: this.state.localUser.nickname,
      //                     isScreenShareActive: this.state.localUser.screenShareActive,
      //                 });
      //             }
      //             this.updateLayout();
      //         },
      //     );
      // }
    });
  }

  subscribeToStreamDestroyed() {
    // On every Stream destroyed...
    this.state.session.on('streamDestroyed', (event) => {
      // Remove the stream from 'subscribers' array
      this.deleteSubscriber(event.stream);
      setTimeout(() => {
        this.checkSomeoneShareScreen();
      }, 20);
      event.preventDefault();
      this.updateLayout();
    });
  }

  subscribeToConnectionCreated() {
    this.state.session.on('connectionCreated', (event) => {
      console.log('CCCCCCCCCCCCCCCCCCCCCCCCCCCCCC');
      console.log(event);
      console.log('CCCCCCCCCCCCCCCCCCCCCCCCCCCCCC');
    });
  }

  subscribeToUserChanged() {
    this.state.session.on('signal:userChanged', (event) => {
      let remoteUsers = this.state.subscribers;
      remoteUsers.forEach((user) => {
        if (user.getConnectionId() === event.from.connectionId) {
          const data = JSON.parse(event.data);
          console.log('EVENTO REMOTE: ', event.data);
          if (data.isAudioActive !== undefined) {
            user.setAudioActive(data.isAudioActive);
          }
          if (data.isVideoActive !== undefined) {
            user.setVideoActive(data.isVideoActive);
          }
          if (data.nickname !== undefined) {
            user.setNickname(data.nickname);
          }
          if (data.isScreenShareActive !== undefined) {
            user.setScreenShareActive(data.isScreenShareActive);
          }
        }
      });
      this.setState(
        {
          subscribers: remoteUsers,
        },
        () => this.checkSomeoneShareScreen(),
      );
    });
  }

  updateLayout() {
    setTimeout(() => {
      this.layout.updateLayout();
    }, 20);
  }

  sendSignalUserChanged(data) {
    const signalOptions = {
      data: JSON.stringify(data),
      type: 'userChanged',
    };
    this.state.session.signal(signalOptions);
  }

  toggleFullscreen() {
    const document = window.document;
    const fs = document.getElementById('container');
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement
    ) {
      if (fs.requestFullscreen) {
        fs.requestFullscreen();
      } else if (fs.msRequestFullscreen) {
        fs.msRequestFullscreen();
      } else if (fs.mozRequestFullScreen) {
        fs.mozRequestFullScreen();
      } else if (fs.webkitRequestFullscreen) {
        fs.webkitRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  }

  screenShare() {
    // const videoSource = navigator.userAgent.indexOf('Firefox') !== -1 ? 'window' : 'screen';
    // const publisher = this.OV.initPublisher(
    //     undefined,
    //     {
    //         videoSource: videoSource,
    //         publishAudio: this.state.localUser.audioActive,
    //         publishVideo: this.state.localUser.videoActive,
    //         mirror: false,
    //     },
    //     (error) => {
    //         if (error && error.name === 'SCREEN_EXTENSION_NOT_INSTALLED') {
    //             this.setState({showExtensionDialog: true});
    //         } else if (error && error.name === 'SCREEN_SHARING_NOT_SUPPORTED') {
    //             alert('Your browser does not support screen sharing');
    //         } else if (error && error.name === 'SCREEN_EXTENSION_DISABLED') {
    //             alert('You need to enable screen sharing extension');
    //         } else if (error && error.name === 'SCREEN_CAPTURE_DENIED') {
    //             alert('You need to choose a window or application to share');
    //         }
    //     },
    // );
    //
    // publisher.once('accessAllowed', () => {
    //     this.state.session.unpublish(this.state.localUser.getStreamManager());
    //     localUser.setStreamManager(publisher);
    //     this.setLocalUserToState('streamManager');
    //     this.state.session.publish(localUser.getStreamManager()).then(() => {
    //         localUser.setScreenShareActive(true);
    //         this.setState({localUser: localUser}, () => {
    //             this.sendSignalUserChanged({isScreenShareActive: localUser.isScreenShareActive()});
    //         });
    //     });
    // });
    // publisher.on('streamPlaying', () => {
    //     this.updateLayout();
    //     publisher.videos[0].video.parentElement.classList.remove('custom-class');
    // });
  }

  closeDialogExtension() {
    this.setState({ showExtensionDialog: false });
  }

  stopScreenShare() {
    // this.state.session.unpublish(localUser.getStreamManager());
    // this.connectWebCam();
  }

  checkSomeoneShareScreen() {
    // let isScreenShared;
    // // return true if at least one passes the test
    // isScreenShared = this.state.subscribers.some((user) => user.isScreenShareActive()) || localUser.isScreenShareActive();
    // const openviduLayoutOptions = {
    //     maxRatio: 3 / 2,
    //     minRatio: 9 / 16,
    //     fixedRatio: isScreenShared,
    //     bigClass: 'OV_big',
    //     bigPercentage: 0.8,
    //     bigFixedRatio: false,
    //     bigMaxRatio: 3 / 2,
    //     bigMinRatio: 9 / 16,
    //     bigFirst: true,
    //     animate: true,
    // };
    // this.layout.setLayoutOptions(openviduLayoutOptions);
    // this.updateLayout();
  }

  toggleChat(property) {
    let display = property;

    if (display === undefined) {
      display = this.state.chatDisplay === 'none' ? 'block' : 'none';
    }
    if (display === 'block') {
      this.setState({ chatDisplay: display, messageReceived: false });
    } else {
      console.log('chat', display);
      this.setState({ chatDisplay: display });
    }
    this.updateLayout();
  }

  checkNotification(event) {
    this.setState({
      messageReceived: this.state.chatDisplay === 'none',
    });
  }

  checkSize() {
    if (document.getElementById('layout').offsetWidth <= 700 && !this.hasBeenUpdated) {
      //   this.toggleChat('none');
      this.hasBeenUpdated = true;
    }
    if (document.getElementById('layout').offsetWidth > 700 && this.hasBeenUpdated) {
      this.hasBeenUpdated = false;
    }
  }

  isOperator() {
    return !!(this.props.auth.user && this.props.auth.user.operator);
  }

  switchCamera(videoDevice) {
    this.OV.getUserMedia({
      audioSource: undefined,
      videoSource: videoDevice.deviceId,
      resolution: '1280x720',
      frameRate: 60,
    })
      .then((media) => {
        console.log('media');
        console.log(media.getVideoTracks());

        const videoStreamTrack = media.getVideoTracks()[0];

        this.publisher
          .replaceTrack(videoStreamTrack)
          .then((res) => {
            console.log('resolve ' + res);
          })
          .catch((error) => {
            console.log('reject');
            console.log(error);
          });
      })
      .catch((e) => {
        console.log('getUserMedia Error');
        console.log(e);

        if (e.name === 'DEVICE_ACCESS_DENIED') {
          console.log('Access denied, trying republishing');

          const newPublisher = this.OV.initPublisher(undefined, {
            // videoSource: isFrontCamera ? videoDevices[1].deviceId : videoDevices[0].deviceId,
            audioSource: undefined,
            videoSource: videoDevice.deviceId,
            resolution: '1280x720',
            frameRate: 60,
            insertMode: 'APPEND',
            mirror: false,
            filter: this.isOperator()
              ? {
                  type: 'GStreamerFilter',
                  options: {
                    command:
                      'gdkpixbufoverlay location=/opt/openvidu/layouts/images/overlay.png alpha=0.1',
                  },
                }
              : null,
          });

          // Unpublishing the old publisher
          this.OV.session.unpublish(this.publisher);

          // Assigning the new publisher to our global variable 'publisher'
          this.publisher = newPublisher;

          // Publishing the new publisher
          this.OV.session.publish(this.publisher);

          this.setState(
            (prevState) => {
              return {
                ...prevState,
                localUser: {
                  ...prevState.localUser,
                  streamManager: this.publisher,
                  videoActive: true,
                },
              };
            },
            () => {
              // this.subscribeToUserChanged();
              // this.subscribeToStreamDestroyed();

              this.state.localUser.streamManager.on('streamPlaying', (e) => {
                this.updateLayout();
                this.publisher.videos[0].video.parentElement.classList.remove('custom-class');
              });
              this.state.localUser.streamManager.publishVideo(this.state.localUser.videoActive);
              this.sendSignalUserChanged({
                isVideoActive: this.state.localUser.videoActive,
              });
              this.updateLayout();
            },
          );
        }
      });
  }

  getDevices() {
    return this.OV.getDevices();
  }

  async getVideoDevices() {
    try {
      const devices = await this.OV.getDevices();

      const videoDevices = devices.filter((device) => {
        return device.kind === 'videoinput';
      });

      return videoDevices;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  renderLocalStream() {
    const localUser = this.state.localUser;

    console.log(localUser);
    console.log(this.isOperator());

    if (localUser && localUser.streamManager) {
      if (!this.isOperator()) {
        if (!localUser.videoActive) {
          return null;
        }
      }
      return (
        <div className="OT_root OT_publisher custom-class" id="localUser">
          <StreamComponent
            isLocalStream={true}
            isOperator={this.isOperator()}
            user={localUser}
            sessionId={this.state.mySessionId}
          />
        </div>
      );
    }
    return null;
  }

  listenToOperatorLeftEvent() {
    if (!this.isOperator()) {
      window.Echo.channel(this.state.mySessionId).listen('.App\\Events\\OperatorLeft', (e) => {
        console.log('Event OperatorLeft');

        // this.leaveSession();
        this.props.history.push('/feedback');
      });
    }
  }

  callErrorHandler() {
    const {
      clientData: { clientId, product },
      setCallError,
      history,
    } = this.props;

    setCallError(true);
    const redirectUrl = `/?sku=${product && product.sku ? product.sku : ''}&client_id=${
      clientId ? clientId : ''
    }`;
    setTimeout(() => {
      history.push(redirectUrl);
    }, 200);
  }

  render() {
    console.log('XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX');

    console.log(this.state.localUser);

    const mySessionId = this.state.mySessionId;
    const localUser = this.state.localUser;
    var chatDisplay = { display: this.state.chatDisplay };

    return (
      <>
        <div className="container" id="container">
          <ToolbarComponent
            isOperator={this.isOperator()}
            sessionId={mySessionId}
            OV={this.OV}
            user={this.state.localUser}
            showNotification={this.state.messageReceived}
            camStatusChanged={this.camStatusChanged}
            micStatusChanged={this.micStatusChanged}
            screenShare={this.screenShare}
            stopScreenShare={this.stopScreenShare}
            toggleFullscreen={this.toggleFullscreen}
            leaveSession={this.leaveSession}
            toggleChat={this.toggleChat}
            getDevices={this.getDevices}
            switchCamera={this.switchCamera}
          />

          <DialogExtensionComponent
            showDialog={this.state.showExtensionDialog}
            cancelClicked={this.closeDialogExtension}
          />

          <div id="layout" className="bounds">
            {this.renderLocalStream()}
            {this.state.subscribers.map((sub, i) => (
              <div key={i} className="OT_root OT_publisher custom-class" id="remoteUsers">
                <StreamComponent
                  isLocalStream={false}
                  isOperator={this.isOperator()}
                  user={sub}
                  sessionId={mySessionId}
                />
              </div>
            ))}
            {localUser && localUser.streamManager && (
              <div className="OT_root OT_publisher custom-class mobile-chat" style={chatDisplay}>
                <ChatComponent
                  user={localUser}
                  chatDisplay={this.state.chatDisplay}
                  close={this.toggleChat}
                  messageReceived={this.checkNotification}
                />
              </div>
            )}
          </div>
        </div>
        <FullPageSpinner show={this.props.OVSession.loading} />
      </>
    );
  }

  /**
   * --------------------------
   * SERVER-SIDE RESPONSIBILITY
   * --------------------------
   * These methods retrieve the mandatory user token from OpenVidu Server.
   * This behaviour MUST BE IN YOUR SERVER-SIDE IN PRODUCTION (by using
   * the API REST, openvidu-java-client or openvidu-node-client):
   *   1) Initialize a session in OpenVidu Server    (POST /api/sessions)
   *   2) Generate a token in OpenVidu Server        (POST /api/tokens)
   *   3) The token must be consumed in Session.connect() method
   */

  getToken() {
    return this.openviduHelper.getToken();
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    clientData: state.clientData,
    OVSession: state.OVSession,
    mediaDevicesState: state.mediaDevicesState,
  };
};

export default connect(mapStateToProps, {
  setOVToken,
  setLoadingOVToken,
  unsetLoadingOVToken,
  setCallError,
})(VideoRoomComponent);
