export default (state = {
    token: null,
    user: null,
    error: null,
    loading: false,
    mrLoading: false,
    localChecked: false
}, action) => {

    switch (action.type) {

        case 'AUTH_ERASE_ERROR':
            return {...state, error: null};

        case 'AUTH_START':
            return {...state, error: null, loading: true};

        case 'AUTH_MR_START':
            return {...state, error: null, mrLoading: true};

        case 'AUTH_AUTO_SUCCESS':
            return {...state, token: action.token, user: action.user ? action.user : "", localChecked: true};

        case 'AUTH_AUTO_FAIL':
            return {...state, localChecked: true};

        case 'AUTH_SUCCESS':
            return {
                ...state,
                token: action.payload.access_token,
                user: action.payload.user ? action.payload.user : "",
                loading: false
            };

        case 'AUTH_MR_SUCCESS':
            return {
                ...state,
                token: action.payload.access_token,
                user: action.payload.user ? action.payload.user : "",
                mrLoading: false
            };

        case 'AUTH_FAIL':
            return {...state, token: null, error: action.error, loading: false};

        case 'AUTH_MR_FAIL':
            return {...state, token: null, error: action.error, mrLoading: false};

        case 'AUTH_LOGOUT':
            return {...state, token: null, error: null, loading: false, mrLoading: false, localChecked: true};

        default:
            return state;

    }
}