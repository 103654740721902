import React from 'react';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {makeStyles, withStyles} from '@material-ui/styles';
import {
    Grid,
    Button,
    TextField,
    Typography,
    Paper
} from '@material-ui/core';
import {connect} from 'react-redux';
import {authEraseError, login} from '../actions';
import CircularProgress from '@material-ui/core/CircularProgress';
import Zoom from '@material-ui/core/Zoom';
import {mrLogin} from "../actions/auth";
import FullPageSpinner from "../utils/FullPageSpinner";

const useStyles = theme => ({
    root: {
        paddingTop: '70px'
    },
    grid: {
        height: '100%'
    },
    name: {
        marginTop: theme.spacing(3),
        color: theme.palette.white
    },
    bio: {
        color: theme.palette.white
    },
    contentContainer: {},
    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    logoImage: {
        marginLeft: theme.spacing(4)
    },
    contentBody: {
        // flexGrow: 1,
        // display: 'flex',
        // alignItems: 'center',
        // [theme.breakpoints.down('md')]: {
        //     justifyContent: 'center'
        // },
        paddingLeft: '10px',
        paddingRight: '10px'
    },
    form: {
        paddingLeft: 100,
        paddingRight: 100,
        paddingTop: 20,
        paddingBottom: 50,
        flexBasis: 700,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2)
        }
    },
    title: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(5),
        textAlign: 'center'
    },
    sugestion: {
        marginTop: theme.spacing(2)
    },
    textField: {
        marginTop: theme.spacing(2),
        '& .MuiOutlinedInput-root': {
            '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(0, 0, 0, 0.4)'
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                border: '1px solid #5285cc'
            }
        },
        '& .MuiFormLabel-root.Mui-focused': {
            color: '#5285cc'
        }
    },
    signInButton: {
        marginTop: '50px',
        paddingTop: '15px',
        paddingBottom: '15px',
        fontSize: '18px'
    },
    notchedOutline: {
        borderWidth: '1px',
        borderColor: 'yellow !important'
    }
});

class LoginView extends React.Component {

    constructor(props) {
        super(props);

        this.locationSearch = props.location.search;
        this.urlParams = new URLSearchParams(this.locationSearch);
        this.mrToken = this.urlParams.get('t') ? decodeURI(this.urlParams.get('t')) : null;

        this.state = {

            login: '',
            password: ''
        };

        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.handleLogin = this.handleLogin.bind(this);
        this.handleValid = this.handleValid.bind(this);
        this.renderError = this.renderError.bind(this);

    }

    componentDidMount() {
        if (this.mrToken) {
            this.props.mrLogin(this.mrToken);
        }
    }

    handleFieldChange(event, field) {
        event.persist();

        if (this.props.auth.error) {
            this.props.authEraseError();
        }

        this.setState((prevState) => {
            if (this.state.submitErrors && this.state.submitErrors[field]) {
                const {submitErrors} = this.state;
                delete submitErrors[field];
                return {...prevState, submitErrors, [field]: event.target.value.trim()};
            } else {
                return {...prevState, [field]: event.target.value.trim()};
            }
        });
    }

    handleLogin(event) {
        event.preventDefault();

        if (this.props.auth.loading) return;

        this.props.login(this.state.login, this.state.password);
    };

    handleValid() {
        if (this.state.login !== '' && this.state.password !== '') {
            return true;
        }
        return false;
    }

    renderError() {
        if (this.props.auth.error) {
            return (
                <div className="" style={{color: 'red', marginTop: '15px'}}>
                    {this.props.auth.error}
                </div>
            );
        }
        return null;
    }

    render() {

        const {classes} = this.props;

        let buttonText = 'Войти';

        if (this.props.auth.loading) {
            buttonText = (
                <React.Fragment>
                    <CircularProgress size={25} style={{color: 'white'}}/>
                    <span style={{marginLeft: '10px'}}>Войти</span>
                </React.Fragment>
            );
        }

        return (
            <>
                <div className="container" id="container">
                    <div className={classes.root}>
                        <Grid
                            className={classes.grid}
                            container
                        >
                            <Grid
                                item
                                lg={3}
                            >
                            </Grid>
                            <Grid
                                className={classes.content}
                                item
                                lg={6}
                                xs={12}
                            >
                                {/*<div className={classes.content}>*/}
                                <div className={classes.contentBody}>
                                    <Zoom in={true}>
                                        <Paper elevation={5}>
                                            <form
                                                className={classes.form}
                                                onSubmit={this.handleLogin}
                                            >
                                                <Typography
                                                    className={classes.title}
                                                    variant="h2"
                                                >
                                                    Вход в Эксперт онлайн
                                                </Typography>
                                                <TextField
                                                    className={classes.textField}
                                                    fullWidth
                                                    label="Логин"
                                                    name="login"
                                                    onChange={(e) => this.handleFieldChange(e, 'login')}
                                                    type="text"
                                                    value={this.state.login}
                                                    variant="outlined"
                                                />
                                                <TextField
                                                    className={classes.textField}
                                                    fullWidth
                                                    label="Пароль"
                                                    name="password"
                                                    onChange={(e) => this.handleFieldChange(e, 'password')}
                                                    type="password"
                                                    value={this.state.password}
                                                    variant="outlined"
                                                />
                                                {this.renderError()}
                                                <Button
                                                    className={classes.signInButton}
                                                    color="primary"
                                                    disabled={!this.handleValid()}
                                                    fullWidth
                                                    type="submit"
                                                    variant="contained"
                                                    style={{width: '100%', height: '61px'}}
                                                >
                                                    {buttonText}
                                                </Button>
                                            </form>
                                        </Paper>
                                    </Zoom>
                                </div>
                                {/*</div>*/}
                            </Grid>
                        </Grid>
                    </div>
                </div>
                <FullPageSpinner show={this.props.auth.mrLoading}/>
            </>
        );
    }
}


LoginView.propTypes = {
    history: PropTypes.object
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    };
};


export default connect(mapStateToProps, {login, mrLogin, authEraseError})(withRouter(withStyles(useStyles)(LoginView)));
