import React from 'react';
import {connect} from "react-redux";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from '@material-ui/core/CircularProgress';

import {operatorChangeStatus, operatorFetchStatus} from "../../actions/operator";

const STATUS_NOT_READY = 'NOT_READY';
const STATUS_READY = 'READY';

const useStyles = theme => ({
    root: {
    },
    label: {
        color: '#ffffff'
    }
});

class OperatorStatus extends React.Component {

    constructor(props) {
        super(props);

        this.intervalHandle = null;

        this.state = {status: null};

        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.getIsReady = this.getIsReady.bind(this);
        this.getOperatorId = this.getOperatorId.bind(this);
        this.setLocalStatusToNull = this.setLocalStatusToNull.bind(this);
        this.setLocalStatus = this.setLocalStatus.bind(this);
        this.getIsDisabled = this.getIsDisabled.bind(this);
        this.getStatusText = this.getStatusText.bind(this);
        this.getLabel = this.getLabel.bind(this);
    }

    handleStatusChange(e) {

        if(this.getIsDisabled()) return;

        this.setLocalStatus(e.target.checked);

        let status;

        if (e.target.checked) {
            status = 'ready';
        } else {
            status = 'not-ready';
        }

        this.props.operatorChangeStatus(this.getOperatorId(), status, this.setLocalStatusToNull);
    }

    setLocalStatus(checked) {
        if (checked) {
            this.setState({status: STATUS_READY});
        } else {
            this.setState({status: STATUS_NOT_READY});
        }
    }

    setLocalStatusToNull() {
        this.setState({status: null});
    }

    componentDidMount() {
        this.props.operatorFetchStatus(this.getOperatorId());

        this.intervalHandle = window.setInterval(() => {this.props.operatorFetchStatus(this.getOperatorId());}, 10000);
    }

    componentWillUnmount() {
        window.clearInterval(this.intervalHandle);
    }

    getIsReady() {

        if (this.state.status !== null) {
            return this.state.status === 'READY';
        }
        return this.props.operatorStatus.status === 'READY';
    }

    getIsDisabled() {
        return this.props.operatorStatus.loading;
    }

    getStatusText() {
        if (this.state.status !== null) {
            return this.state.status;
        }
        return this.props.operatorStatus.status;
    }

    getLabel(){
        if(this.getIsDisabled()){
            return (
                <CircularProgress style={{
                    color: 'white',
                    width: '20px',
                    height: '20px',
                    position: 'relative',
                    top: '3px'}} />
            );
        }
        return this.getStatusText();
    }

    getOperatorId() {
        return this.props.auth.user.operator.id;
    }

    render() {

        return (
            <div className="OperatorStatus">
                <FormControlLabel
                    control={
                        <Switch
                            checked={this.getIsReady()}
                            onChange={this.handleStatusChange}
                            // disabled={this.getIsDisabled()}
                            color="secondary"
                        />
                    }
                    label={this.getLabel()}
                    labelPlacement="start"
                    classes={{label: this.props.classes.label}}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        operatorStatus: state.operatorStatus
    };
};

export default connect(mapStateToProps, {
    operatorFetchStatus,
    operatorChangeStatus
})(withStyles(useStyles)(OperatorStatus));

