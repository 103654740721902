import React from 'react';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import ru from "date-fns/locale/ru";

export default function MaterialDatePicker(props) {

    return (
        <MuiPickersUtilsProvider locale={ru} utils={DateFnsUtils}>
            <KeyboardDatePicker
                margin="normal"
                label={props.label}
                format="dd.MM.yyyy"
                value={props.date}
                onChange={props.handleDateChange}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
            />
        </MuiPickersUtilsProvider>
    );
}