import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Slide from '@material-ui/core/Slide';

import customAxios from '../../utils/customAxios';
import apiRoutes from '../../apiRoutes';

//styles
import StreamerDialogStyles from './StreamerDialogStyles';

const DialogTitle = withStyles(StreamerDialogStyles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class DeleteStreamer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      submitLoading: false,
      error: null,
    };

    this.handleDialogOpen = this.handleDialogOpen.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.submitHandler = this.submitHandler.bind(this);
    this.submitToApi = this.submitToApi.bind(this);
    this.renderDialogText = this.renderDialogText.bind(this);
  }

  handleDialogOpen = () => {
    this.props.deleteDialogToggle(true);
  };

  handleDialogClose = () => {
    this.props.deleteDialogToggle(false);
  };

  renderDialogText() {
    const { deleteStreamer } = this.props;

    if (deleteStreamer) {
      return (
        <div>
          Имя: {deleteStreamer?.user?.name ?? ''}, Email: {deleteStreamer?.user?.email ?? ''}
        </div>
      );
    }
    return null;
  }

  submitHandler(e) {
    e.preventDefault();

    if (this.state.submitLoading) return;

    this.submitToApi(this.props);
  }

  submitToApi({ deleteDialogToggle, fetchStreamersFromApi, deleteStreamer }) {
    this.setState({ submitLoading: true });
    customAxios
      .delete(apiRoutes.OPERATORS + `/${deleteStreamer.id}`)
      .then((res) => {
        this.setState(
          {
            submitLoading: false,
            error: null,
          },
          () => {
            deleteDialogToggle(false);
            fetchStreamersFromApi();
          },
        );
      })
      .catch((error) => {
        this.setState({ submitLoading: false });
      });
  }

  render() {
    const { classes } = this.props;

    let buttonText = 'Удалить';

    if (this.state.submitLoading) {
      buttonText = (
        <React.Fragment>
          <CircularProgress size={25} style={{ color: 'white' }} />
          <span style={{ marginLeft: '10px' }}>Удалить</span>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <Dialog
          TransitionComponent={Transition}
          fullWidth={true}
          maxWidth="sm"
          open={this.props.deleteDialogOpen}
          onClose={this.handleDialogClose}>
          <DialogTitle className={classes.title} onClose={this.handleDialogClose}>
            Удаление стримера
          </DialogTitle>

          <DialogContent>
            {this.renderDialogText()}
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Button
                  className={classes.signInButton}
                  color="primary"
                  fullWidth
                  type="submit"
                  variant="contained"
                  style={{ width: '100%' }}
                  onClick={this.submitHandler}>
                  {buttonText}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  className={classes.signInButton}
                  color="secondary"
                  fullWidth
                  type="submit"
                  variant="contained"
                  style={{ width: '100%' }}
                  onClick={this.handleDialogClose}>
                  Отмена
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default withStyles(StreamerDialogStyles)(DeleteStreamer);
