export const setMediaAudio = (audio) => {
    return {
        type: 'MEDIA_SET_AUDIO',
        audio: audio
    };
};

export const setMediaVideo = (video) => {
    return {
        type: 'MEDIA_SET_VIDEO',
        video: video
    };
};