import React, { Component } from 'react';
import './ToolbarComponent.css';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import Mic from '@material-ui/icons/Mic';
import MicOff from '@material-ui/icons/MicOff';
import Videocam from '@material-ui/icons/Videocam';
import VideocamOff from '@material-ui/icons/VideocamOff';
import Fullscreen from '@material-ui/icons/Fullscreen';
import FullscreenExit from '@material-ui/icons/FullscreenExit';
import PictureInPicture from '@material-ui/icons/PictureInPicture';
import ScreenShare from '@material-ui/icons/ScreenShare';
import StopScreenShare from '@material-ui/icons/StopScreenShare';
import Tooltip from '@material-ui/core/Tooltip';
import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew';
import QuestionAnswer from '@material-ui/icons/QuestionAnswer';
import SwitchVideoIcon from '@material-ui/icons/SwitchVideo';
import allo_logo from '../../images/logo_allo_white.svg';
import { withRouter } from 'react-router-dom';

import IconButton from '@material-ui/core/IconButton';
import { connect } from 'react-redux';
import SwitchCameraDialog from '../SwitchCameraDialog';

class ToolbarComponent extends Component {
    constructor(props) {
        super(props);
        this.state = { fullscreen: false, switchCameraDialogOpen: false };
        this.camStatusChanged = this.camStatusChanged.bind(this);
        this.micStatusChanged = this.micStatusChanged.bind(this);
        this.screenShare = this.screenShare.bind(this);
        this.stopScreenShare = this.stopScreenShare.bind(this);
        this.toggleFullscreen = this.toggleFullscreen.bind(this);
        this.leaveSession = this.leaveSession.bind(this);
        this.toggleChat = this.toggleChat.bind(this);
        this.toggleSwitchCameraDialog = this.toggleSwitchCameraDialog.bind(this);
        this.closeSwitchCameraDialog = this.closeSwitchCameraDialog.bind(this);
        this.renderVideoButton = this.renderVideoButton.bind(this);
    }

    micStatusChanged() {
        this.props.micStatusChanged();
    }

    camStatusChanged() {
        this.props.camStatusChanged();
    }

    screenShare() {
        this.props.screenShare();
    }

    stopScreenShare() {
        this.props.stopScreenShare();
    }

    toggleFullscreen() {
        this.setState({ fullscreen: !this.state.fullscreen });
        this.props.toggleFullscreen();
    }

    leaveSession() {
        //this.props.leaveSession();

        if (this.props.auth.user?.operator) {
            this.props.history.push('/active-calls');
        } else {
            // this.props.authUnSetUserName();
            // window.location.href = 'https://allo.ua';
            this.props.history.push('/feedback');
        }
    }

    toggleChat() {
        this.props.toggleChat();
    }

    toggleSwitchCameraDialog() {
        this.setState((prevState) => {
            return { ...prevState, switchCameraDialogOpen: !prevState.switchCameraDialogOpen };
        });
    }

    closeSwitchCameraDialog() {
        this.setState((prevState) => {
            return { ...prevState, switchCameraDialogOpen: false };
        });
    }

    renderVideoButton(localUser) {
        console.log('localUser.videoActive');
        console.log(localUser);

        return (
            <IconButton
                color="inherit"
                className="navButton"
                id="navCamButton"
                onClick={this.camStatusChanged}
                disabled={!this.props.isOperator && !this.props.clientData.authorizedClient}>
                {localUser && localUser.videoActive ? (
                    <Videocam />
                ) : (
                    <VideocamOff color="inherit" />
                )}
            </IconButton>
        );
    }

    render() {
        const mySessionId = this.props.sessionId;
        const localUser = this.props.user;
        return (
            <AppBar className="toolbar" id="header">
                <Toolbar className="toolbar">
                    <div id="navSessionInfo">
                        <img id="header_img" alt="Allo logo" src={allo_logo} />

                        {this.props.sessionId && (
                            <div id="titleContent">
                                <span id="session-title">{mySessionId}</span>
                            </div>
                        )}
                    </div>

                    <div className="buttonsContent">
                        <IconButton
                            color="inherit"
                            className="navButton"
                            id="navMicButton"
                            onClick={this.micStatusChanged}>
                            {localUser && localUser.audioActive ? (
                                <Mic />
                            ) : (
                                <MicOff color="inherit" />
                            )}
                        </IconButton>

                        {this.renderVideoButton(localUser)}

                        {/*<IconButton color="inherit" className="navButton" onClick={this.screenShare}>*/}
                        {/*{localUser && localUser.screenShareActive ? <PictureInPicture /> : <ScreenShare />}*/}
                        {/*</IconButton>*/}

                        {/*{localUser && localUser.screenShareActive && (*/}
                        {/*<IconButton onClick={this.stopScreenShare} id="navScreenButton">*/}
                        {/*<StopScreenShare color="inherit" />*/}
                        {/*</IconButton>*/}
                        {/*)}*/}

                        <IconButton
                            color="inherit"
                            className="navButton"
                            onClick={this.toggleSwitchCameraDialog}
                            id="navSwitchButton"
                            disabled={
                                (!this.props.isOperator &&
                                    !this.props.clientData.authorizedClient) ||
                                !localUser.videoActive
                            }>
                            <SwitchVideoIcon />
                        </IconButton>

                        <IconButton
                            color="inherit"
                            className="navButton"
                            onClick={this.toggleFullscreen}>
                            {localUser && this.state.fullscreen ? (
                                <FullscreenExit />
                            ) : (
                                <Fullscreen />
                            )}
                        </IconButton>

                        <IconButton
                            color="inherit"
                            className="navButton"
                            onClick={this.leaveSession}
                            id="navLeaveButton">
                            <PowerSettingsNew />
                        </IconButton>
                        <IconButton color="inherit" onClick={this.toggleChat} id="navChatButton">
                            {this.props.showNotification && <div id="point" className="" />}
                            <Tooltip title="Chat">
                                <QuestionAnswer />
                            </Tooltip>
                        </IconButton>
                    </div>
                </Toolbar>
                <SwitchCameraDialog
                    OV={this.props.OV}
                    open={this.state.switchCameraDialogOpen}
                    closeSwitchCameraDialog={this.closeSwitchCameraDialog}
                    switchCamera={this.props.switchCamera}
                />
            </AppBar>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        clientData: state.clientData,
    };
};

export default connect(mapStateToProps, {})(withRouter(ToolbarComponent));
