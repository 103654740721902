import React from 'react';
import StartViewCounterSocial from './StartViewCounterSocial';
import StartViewError from './StartViewError';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { setCallError } from '../../actions/appError';

let START_COUNT = 99;

const useStyles = (theme) => ({
    title: {
        textAlign: 'center',
        margin: '20px 0px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '20px',
        },
    },
});

class StartViewCounter extends React.Component {
    constructor(props) {
        super(props);

        this.intervalHandler = null;

        this.state = { count: START_COUNT };
        this.handleTryAgain = this.handleTryAgain.bind(this);
        this.updateCount = this.updateCount.bind(this);
    }

    componentDidMount() {
        if (this.props.callError) {
            this.setState({ count: 0 });
        }
        this.intervalHandler = window.setInterval(() => {
            this.setState((prevState) => {
                return {
                    count: prevState.count - 1,
                };
            });
        }, 1000);
    }

    componentWillUnmount() {
        window.clearInterval(this.intervalHandler);
    }

    splitCountNumber(number) {
        number = number.split('');
        if (number < 10) {
            return (
                <div className="count-number__box">
                    <span className="count-number">0</span>
                    <span className="count-number">{number[0]}</span>
                </div>
            );
        } else {
            return (
                <div className="count-number__box">
                    <span className="count-number">{number[0]}</span>
                    <span className="count-number">{number[1]}</span>
                </div>
            );
        }
    }

    renderContentBody() {
        const { classes, loading } = this.props;

        let buttonText = 'Спробувати знову';

        if (loading) {
            buttonText = (
                <React.Fragment>
                    <div style={{ position: 'relative' }}>
                        <CircularProgress
                            size={25}
                            style={{
                                position: 'absolute',
                                left: '0',
                                top: '2px',
                                color: 'white',
                            }}
                        />
                        <span>Спробувати знову</span>
                    </div>
                </React.Fragment>
            );
        }

        if (this.state.count >= 1) {
            return (
                <div
                    style={{
                        // margin: '250px 0px',
                        textAlign: 'center',
                        fontSize: '80px',
                        fontWeight: 'bold',
                        color: '#e31837',
                    }}>
                    <Typography variant="h2" className={classes.title}>
                        З'єднання з оператором
                    </Typography>
                    {this.splitCountNumber(String(this.state.count))}
                    <div className="youtube-video">
                        <iframe
                            src="https://www.youtube.com/embed/gjTH2IpVn4E?autoplay=1&mute=1"
                            frameBorder="0"
                            allow="autoplay; encrypted-media"
                            allowFullScreen
                            title="video"
                        />
                    </div>
                </div>
            );
        } else if (this.props.callError) {
            return (
                <StartViewError
                    loading={loading}
                    productLink={this.props.clientData.product && this.props.clientData.product.url}
                    tryAgain={this.handleTryAgain}
                />
            );
        } else {
            return (
                <div className="startView-info">
                    <div className="startView-title">Нажаль, усі оператори зайняті.</div>
                    <div className="startView-subtitle">
                        Ви можете <a href={this.props.clientData.product && this.props.clientData.product.url}>повернутись</a>, або
                        спробувати ще раз
                    </div>
                    <Button className="startView-btn" color="primary" variant="contained" onClick={this.handleTryAgain}>
                        {buttonText}
                    </Button>
                </div>
            );
        }
    }

    handleTryAgain(e, unsetCallError) {
        if (unsetCallError) {
            this.props.createToken(e, this.updateCount, this.handleUnsetCallError);
        } else {
            this.props.createToken(e, this.updateCount);
        }
    }

    updateCount() {
        this.setState((state) => {
            return { count: START_COUNT };
        });
    }

    handleUnsetCallError = () => {
        this.props.setCallError(false);
    };

    render() {
        return (
            <>
                {this.renderContentBody()}
                <StartViewCounterSocial />
            </>
        );
    }
}

export default withStyles(useStyles)(StartViewCounter);
