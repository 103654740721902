import React from 'react';

import Grid from "@material-ui/core/Grid";
import Zoom from "@material-ui/core/Zoom";
import Paper from "@material-ui/core/Paper";

export default function ChartInPaper(props){
    return (
        <Grid
            item
            xs={12}
        >
            <Zoom in={true}>
                <Paper elevation={7} style={{margin: '10px'}}>
                    <div style={{maxWidth: '100%', padding: '7px'}}>
                        {props.children}
                    </div>
                </Paper>
            </Zoom>
        </Grid>
    );
}