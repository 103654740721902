import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

//images
import errorImage1 from '../../images/errorPopups/error_image_1.png';
import errorImage2 from '../../images/errorPopups/error_image_2.png';
import errorImage3 from '../../images/errorPopups/error_image_3.png';
import errorImage4 from '../../images/errorPopups/error_image_4.png';
import errorImage5 from '../../images/errorPopups/error_image_5.png';

const errorData = [
    {
        title: 'Самі в шоці,',
        text: 'але інтернет зламався! Вже працюємо над цим :)',
        image: errorImage1,
    },
    {
        title: 'Трясця!',
        text: 'Де поділася сторінка?! Пішли шукати.',
        image: errorImage2,
    },
    {
        title: 'От халепа, щось зламалося!',
        text: 'Аллоїд вже тут,  скоро все полагодить!',
        image: errorImage3,
    },
    {
        title: 'Якась технічна фігня :(',
        text: 'Скоро все відновимо!',
        image: errorImage4,
    },
    {
        title: 'Упс...',
        text: 'Якось воно само так вийшло :(',
        image: errorImage5,
    },
];

const randomItem = Math.floor(Math.random() * errorData.length);

export default function StartViewError({ loading, productLink, tryAgain }) {
    let buttonText = 'Спробувати знову';

    if (loading) {
        buttonText = (
            <React.Fragment>
                <div style={{ position: 'relative' }}>
                    <CircularProgress
                        size={25}
                        style={{
                            position: 'absolute',
                            left: '10px',
                            top: '2px',
                            color: 'white',
                        }}
                    />
                    <span>Спробувати знову</span>
                </div>
            </React.Fragment>
        );
    }

    return (
        <div>
            <Grid container className="error-container">
                <Grid className="error-item" item xs={12} sm={5}>
                    <img src={errorData[randomItem].image} />
                </Grid>
                <Grid className="error-item" item xs={12} sm={7}>
                    <Typography variant="h2" className="error-title">
                        {errorData[randomItem].title}
                    </Typography>

                    <Typography variant="body1" className="error-text">
                        {errorData[randomItem].text}
                    </Typography>

                    <div className="error-back">
                        Ви можете <a href={productLink}>повернутись</a>, або спробувати ще раз
                    </div>
                    <Button className="error-btn startView-btn" color="primary" variant="contained" onClick={(e) => tryAgain(e, true)}>
                        {buttonText}
                    </Button>
                </Grid>
            </Grid>
            <div className="error-decor"></div>
        </div>
    );
}
