import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';

import ReactNotifications from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import 'animate.css';

import RouteWithLayout from './components/RouteWithLayout';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';
import StartView from './components/StartView/StartView';
import VideoRoomComponent from './components/VideoRoomComponent';
import ActiveCalls from './components/ActiveCalls';
import LoginView from './components/LoginView';
import FullPageSpinner from './utils/FullPageSpinner';
import StreamersView from './components/StreamersView';
import StreamVideoArchiveView from './components/StreamVideoArchiveView';
import CallInfo from './components/CallInfo';
import ClientFeedback from './components/ClientFeedback/ClientFeedback';
import SWNotification from './utils/SWNotification';
import PermissionValidator from './utils/PermissionValidator';
import Dashboard from './components/dashboard/Dashboard';
import EchoListenerClient from './utils/EchoListenerClient';
import ArchiveCalls from './components/ArchiveCalls';
import OperatorCallsReport from './components/OperatorCallsReport';
import { ErrorViewPopups } from './components/ErrorView';

const Routes = (props) => {
    let routes = <div className="">checking...</div>;

    if (props.auth.localChecked) {
        if (props.auth.token !== null) {
            const ProtectedRoute = ({ component: Component, validator: passValidation, ...rest }) => {
                if (passValidation()) {
                    return <RouteWithLayout {...rest} component={Component} />;
                } else {
                    return <Redirect to={{ pathname: '/login' }} />;
                }
            };

            routes = (
                <React.Fragment>
                    <ReactNotifications />
                    <SWNotification />
                    <Switch>
                        <Route component={VideoRoomComponent} exact path="/call/:id" />
                        {/*<RouteWithLayout*/}
                        {/*// component={CallInfo}*/}
                        {/*layout={MainLayout}*/}
                        {/*exact*/}
                        {/*path="/call-info/:id"*/}
                        {/*render={({ match }) => <CallInfo key={match.params.id || 'empty'} />}*/}
                        {/*/>*/}
                        <Route
                            exact
                            path="/call-info/:id"
                            render={({ match }) => (
                                <MainLayout>
                                    <CallInfo key={match.params.id || 'empty'} />
                                </MainLayout>
                            )}
                        />
                        <RouteWithLayout component={ActiveCalls} layout={MainLayout} exact path="/active-calls" />
                        <RouteWithLayout component={ArchiveCalls} layout={MainLayout} exact path="/archive-calls" />
                        <ProtectedRoute
                            component={OperatorCallsReport}
                            layout={MainLayout}
                            exact
                            path="/report/operators"
                            validator={PermissionValidator.canViewReports}
                        />
                        <ProtectedRoute
                            component={StreamersView}
                            layout={MainLayout}
                            exact
                            path="/streamers"
                            validator={PermissionValidator.canViewOperators}
                        />
                        <ProtectedRoute
                            component={StreamVideoArchiveView}
                            layout={MainLayout}
                            exact
                            path="/stream-recordings"
                            validator={PermissionValidator.canViewRawRecordings}
                        />

                        {/*<RouteWithLayout*/}
                        {/*component={Dashboard}*/}
                        {/*layout={MainLayout}*/}
                        {/*exact*/}
                        {/*path="/dashboard"*/}
                        {/*/>*/}

                        <ProtectedRoute
                            component={Dashboard}
                            layout={MainLayout}
                            exact
                            path="/statistics"
                            validator={PermissionValidator.canViewReports}
                        />
                        <Redirect to="/active-calls" />
                    </Switch>
                    <FullPageSpinner show={props.OVSession.loading} />
                </React.Fragment>
            );
        } else {
            routes = (
                <React.Fragment>
                    {/*<NavBar/>*/}
                    <ReactNotifications />
                    <EchoListenerClient />
                    <Switch>
                        <RouteWithLayout component={StartView} layout={MinimalLayout} exact path="/" />
                        <RouteWithLayout component={ClientFeedback} layout={MinimalLayout} exact path="/feedback" />
                        <Route component={VideoRoomComponent} exact path="/call/:id" />
                        {/*<RouteWithLayout*/}
                        {/*component={LegalTerms}*/}
                        {/*layout={MinimalLayout}*/}
                        {/*exact*/}
                        {/*path="/legal-terms"*/}
                        {/*/>*/}
                        <RouteWithLayout component={LoginView} layout={MinimalLayout} exact path="/login" />

                        <RouteWithLayout component={ErrorViewPopups} layout={MinimalLayout} exact path="/error" />

                        {localStorage.getItem('operator') ? <Redirect to="/login" /> : <Redirect to="/" />}
                    </Switch>
                </React.Fragment>
            );
        }
    }

    return routes;
};

const mapStateToProps = (state) => {
    return {
        OVSession: state.OVSession,
    };
};

export default connect(mapStateToProps, {})(Routes);
