import React from 'react';
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Slide from "@material-ui/core/Slide";
import Fade from "@material-ui/core/Fade";

function Transition(props) {
    return <Slide {...props} direction="up" />;
}

export function SnackBarAlert(props){
    return (
        <Snackbar open={props.open}
                  autoHideDuration={10000}
                  onClose={props.closeHandler}
                  TransitionComponent={Transition}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
            <Alert elevation={6}
                   variant="filled"
                   onClose={props.closeHandler}
                   severity="error"
                   style={{fontSize: '15px'}}
            >

                {props.text}
            </Alert>
        </Snackbar>
    );
}