import React from 'react';
import moment from 'moment';

import {withStyles} from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Zoom from "@material-ui/core/Zoom";
import Paper from "@material-ui/core/Paper";
import MaterialTable from "material-table";
import materialTableLocalization from "../utils/materialTableLocalization";

import customAxios from "../utils/customAxios";
import apiRoutes from "../apiRoutes";
import DatePickerBlock from "../utils/DatePickerBlock";

const useStyles = theme => ({
    root: {},
    grid: {
        height: '100%'
    },
    name: {
        marginTop: theme.spacing(3),
        color: theme.palette.white
    },
    contentContainer: {},
    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    contentBody: {
        marginTop: '50px',
        paddingLeft: '10px',
        paddingRight: '10px'
    }
});

class ArchiveCalls extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dateFrom: moment().subtract(0, 'days').valueOf(),
            dateTo: new Date(),
            calls: [],
            loading: false,
            error: null
        };

        this.fetchCallsFromApi = this.fetchCallsFromApi.bind(this);
        this.getTransformedCallsFromState = this.getTransformedCallsFromState.bind(this);
        this.renderCallsTable = this.renderCallsTable.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
    }

    componentDidMount() {
        this.fetchCallsFromApi();
    }

    fetchCallsFromApi() {

        if(this.state.loading) return;

        this.setState({loading: true, error: null});

        customAxios.post(apiRoutes.CALL_INFO, {
            from: moment(this.state.dateFrom).startOf('day').unix(),
            to: moment(this.state.dateTo).endOf('day').unix()
        })
            .then((res) => {
                this.setState({
                    calls: res.data.data ? res.data.data : [],
                    loading: false,
                    error: res.data.data && res.data.data.length === 0 ?
                        'Звонков согласно заданных параметров не найдено' : null
                });
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    error: 'Ошибка получения статистики по звонкам'
                });
            });
    }

    getTransformedCallsFromState() {

        const calls = this.state.calls.map((call) => {

            const modifiedCreatedAt = moment
                .unix(call.created_at)
                .format('DD.MM.YYYY HH:mm');

            return {
                createdAt: modifiedCreatedAt,
                ...call
            };
        });
        return calls;
    }

    handleDateChange(field, val) {
        this.setState({[field]: val});
    }

    handleLinkClick(rowDate) {
        const linkUrl = rowDate.client_video_link;
        const win = window.open(linkUrl, '_blank');
        win.focus();
    }

    renderCallsTable() {

        if (this.state.error) {
            return (
                <div style={{textAlign: 'center', marginTop: '100px', marginBottom: '100px', padding: '50px 10px'}}>
                    {this.state.error}
                </div>
            );
        } else {
            return (
                <MaterialTable
                    isLoading={this.state.loading}
                    columns={[
                        {title: 'Дата', field: 'createdAt', width: '140px'},
                        {title: 'ID сессии', field: 'session_id'},
                        {title: 'Продолжительность, с', field: 'duration'},
                        {title: 'Клиент', field: 'client.client_name'},
                        {title: 'Оператор', field: 'operator.user.name'},
                        {title: 'Товар', field: 'product_name'},
                        {title: 'SKU', field: 'sku'},
                        // {title: 'Причина обращения', field: 'subject.title'},

                    ]}
                    data={this.getTransformedCallsFromState()}
                    actions={[
                        rowData => ({
                            icon: 'link',
                            tooltip: 'Просмотр записи консультации',
                            onClick: (event, rowData) => {
                                this.handleLinkClick(rowData, true)
                            },
                            disabled: !!!rowData.client_video_link
                        })
                    ]}
                    localization={materialTableLocalization}
                    title=""
                    options={{
                        pageSize: 100,
                        pageSizeOptions: [20, 50, 100],
                        emptyRowsWhenPaging: false,
                        exportButton: false,
                        filtering: true
                    }}
                />
            );
        }
    }

    render() {

        const {classes} = this.props;

        return (
            <div>
                <div className={classes.root}>
                    <Grid
                        className={classes.grid}
                        container
                    >
                        <Grid
                            className={classes.content}
                            item
                            xs={12}
                        >
                            <div className={classes.content}>
                                <div className={classes.contentBody}>
                                    <Typography
                                        align="center"
                                        gutterBottom
                                        variant="h4"
                                        style={{fontSize: '24px'}}
                                    >
                                        <span style={{color: '#37474f'}}>
                                            Статистика звонков
                                        </span>
                                    </Typography>


                                    <DatePickerBlock
                                        handleDateChange={this.handleDateChange}
                                        dateFrom={this.state.dateFrom}
                                        dateTo={this.state.dateTo}
                                        submitHandler={() => {this.fetchCallsFromApi()}}
                                    />

                                    <Zoom in={true}>
                                        <Paper elevation={7}>
                                            <div style={{maxWidth: '100%', marginTop: '30px'}}>
                                                {this.renderCallsTable()}
                                            </div>
                                        </Paper>
                                    </Zoom>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        );
    }
}

export default withStyles(useStyles)(ArchiveCalls);