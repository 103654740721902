import React from 'react';
import StarRating from './StarRating';
import Comment from './Comment';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Zoom } from '@material-ui/core';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/styles';
import './ClientFeedback.scss';
import SubmitButton from './SubmitButton';
import SkipButton from './SkipButton';
import { connect } from 'react-redux';
import ClientRecording from './ClientRecording';
import customAxios from '../../utils/customAxios';
import apiRoutes from '../../apiRoutes';

const useStyles = (theme) => ({
    root: {
        // paddingTop: '40px',
        paddingBottom: '20px',
    },
    grid: {
        height: '100%',
    },
    name: {
        marginTop: theme.spacing(3),
        color: theme.palette.white,
    },
    contentContainer: {},
    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    logoImage: {
        marginLeft: theme.spacing(4),
    },
    title: {
        textAlign: 'center',
        margin: '20px 0px',
    },
    contentBody: {
        paddingLeft: '10px',
        paddingRight: '10px',
    },
    form: {
        paddingLeft: 100,
        paddingRight: 100,
        paddingTop: 20,
        paddingBottom: 50,
        flexBasis: 700,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    },
    signInButton: {
        marginTop: '50px',
        paddingTop: '15px',
        paddingBottom: '15px',
        fontSize: '18px',
    },
    notchedOutline: {
        borderWidth: '1px',
        borderColor: 'yellow !important',
    },
});

class ClientFeedback extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            consultationRating: 0,
            videoRating: 0,
            comment: '',
            step: 1,
        };

        this.handleClickSubmit = this.handleClickSubmit.bind(this);
        this.redirectToAllo = this.redirectToAllo.bind(this);
        this.handleClickSkip = this.handleClickSkip.bind(this);
        this.sendClientFeedback = this.sendClientFeedback.bind(this);
        this.getClientFeedbackObject = this.getClientFeedbackObject.bind(this);
    }

    componentDidMount() {
        if (!this.props.OVSession.sessionId) {
            this.props.history.push('/');
        }
    }

    getClientFeedbackObject() {
        return {
            sessionId: this.props.OVSession.sessionId,
            consQuality: this.state.consultationRating !== 0 ? this.state.consultationRating : null,
            videoQuality: this.state.videoRating !== 0 ? this.state.videoRating : null,
            review: this.state.comment !== '' ? this.state.comment : null,
        };
    }

    sendClientFeedback() {
        customAxios
            .post(apiRoutes.NPS, this.getClientFeedbackObject())
            .then((res) => {
                console.log(res.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    handleClickSubmit(e) {
        e.preventDefault();
        this.sendClientFeedback();
        this.setState({ step: 2 });
        window.setTimeout(() => {
            this.redirectToAllo();
        }, 1000);
    }

    redirectToAllo() {
        if (this.props.clientData.product && this.props.clientData.product.url) {
            window.location.href = this.props.clientData.product.url;
        } else {
            window.location.href = 'https://allo.ua';
        }
    }

    handleClickSkip(e) {
        e.preventDefault();
        this.redirectToAllo();
    }

    render() {
        const { classes } = this.props;

        return (
            <div className="container" id="container">
                {this.state.step === 1 && (
                    <div className={classes.root}>
                        <Grid className={classes.grid} container>
                            <Grid item lg={3}></Grid>
                            <Grid className={classes.content} item lg={6} xs={12}>
                                <div className={classes.contentBody}>
                                    <Zoom in={true}>
                                        <div className="">
                                            <Paper elevation={10} style={{ padding: '20px 10px' }}>
                                                <div className="title">Оцініть, будь ласка, сервіс</div>
                                                <div className="" style={{ marginTop: '25px', textAlign: 'center' }}>
                                                    <div style={{ marginBottom: '10px' }}>Якість консультації</div>
                                                    <StarRating
                                                        rating={this.state.consultationRating}
                                                        ratingChangeHandler={(e, value) => {
                                                            this.setState({ consultationRating: value });
                                                        }}
                                                    />
                                                </div>

                                                <div className="" style={{ marginTop: '25px', textAlign: 'center' }}>
                                                    <div style={{ marginBottom: '10px' }}>Якість відео</div>
                                                    <StarRating
                                                        rating={this.state.videoRating}
                                                        ratingChangeHandler={(e, value) => {
                                                            this.setState({ videoRating: value });
                                                        }}
                                                    />
                                                </div>

                                                <Comment
                                                    rating={3}
                                                    label="Бажаєте щось добавити?"
                                                    commentChangeHandler={(e) => {
                                                        this.setState({ comment: e.target.value });
                                                    }}
                                                />

                                                <ClientRecording />

                                                <div>
                                                    <Grid container spacing={3} direction="row" justify="center" alignItems="center">
                                                        <Grid item xs={12} md={8} lg={6}>
                                                            <div className="button-block">
                                                                <SubmitButton
                                                                    rating={3}
                                                                    name="Оцінити"
                                                                    // checkActiveSubmitButton={this.checkActiveSubmitButton}
                                                                    handleClickSubmit={this.handleClickSubmit}
                                                                />

                                                                <SkipButton text="Пропустити" skipAnswer={this.handleClickSkip} />
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </Paper>
                                        </div>
                                    </Zoom>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                )}
                {this.state.step === 2 && (
                    <div className={classes.root}>
                        <Grid className={classes.grid} container>
                            <Grid item lg={3}></Grid>
                            <Grid className={classes.content} item lg={6} xs={12}>
                                <div className={classes.contentBody}>
                                    <Zoom in={true}>
                                        <div className="">
                                            <Paper elevation={10} style={{ marginTop: '100px', padding: '50px 10px' }}>
                                                <div className="title">Дякуємо за оцінку!</div>
                                            </Paper>
                                        </div>
                                    </Zoom>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        clientData: state.clientData,
        OVSession: state.OVSession,
    };
};

export default connect(mapStateToProps, {})(withRouter(withStyles(useStyles)(ClientFeedback)));
