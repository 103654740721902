import React, { useEffect, useState } from 'react';
import customAxios from '../../utils/customAxios';
import apiRoutes from '../../apiRoutes';

import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CloseIcon from '@material-ui/icons/Close';

export default function ProductInfo({ sessionId, isOperator, userType }) {
    const [open, setOpen] = useState(false);
    const [productInfo, setProductInfo] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchProductInfo = () => {
        setLoading(true);

        customAxios
            .get(apiRoutes.CALL_INFO + `/${sessionId}`)
            .then((res) => {
                setProductInfo(res.data.data);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchProductInfo();
    }, [sessionId]);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const renderProductInfo = () => {
        if (loading) {
            return <CircularProgress size={20} color="white" />;
        } else {
            return (
                <div className="product-info">
                    <div>{productInfo.product_name}</div>
                    <div>Код товара: {productInfo.sku}</div>
                </div>
            );
        }
    };

    return (
        <div>
            {isOperator && userType !== 'local' && (
                <IconButton id="productInfoIcon" onClick={handleOpen}>
                    <InfoOutlinedIcon />
                </IconButton>
            )}

            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={open}
                // autoHideDuration={10000}
                onClose={handleClose}
                message={renderProductInfo()}
                action={
                    <>
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={handleClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </>
                }
            />
        </div>
    );
}
