import React from 'react';
import CircularProgress from "@material-ui/core/CircularProgress";
import './FullPageSpinner.scss';


function FullPageSpinner(props){
    return (
        <div className={`FullPageSpinner ${props.show ? 'show' : ''}`}>
            <CircularProgress size={50}/>
        </div>
    );
}

export default FullPageSpinner;