import { createStyles } from '@material-ui/core/styles';

const StreamerDialogStyles = (theme) =>
  createStyles({
    form: {
      display: 'flex',
      flexDirection: 'column',
      margin: 'auto',
      width: '100%',
    },
    title: {
      textAlign: 'center',
      padding: '25px',
      '& .MuiTypography-root.MuiTypography-h6': {
        fontSize: '20px',
      },
    },
    formControl: {
      marginTop: theme.spacing(2),
      minWidth: 120,
    },
    formControlLabel: {
      marginTop: theme.spacing(1),
    },
    textField: {
      marginTop: theme.spacing(2),
      '& .MuiOutlinedInput-root': {
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: 'rgba(0, 0, 0, 0.4)',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: '1px solid #5285cc',
        },
      },
      '& .MuiFormLabel-root.Mui-focused': {
        color: '#5285cc',
      },
    },
    signInButton: {
      marginTop: '30px',
      marginBottom: '20px',
      paddingTop: '15px',
      paddingBottom: '15px',
      fontSize: '16px',
    },
    notchedOutline: {
      borderWidth: '1px',
      borderColor: 'yellow !important',
    },
    closeButton: {
      position: 'absolute',
      right: '5px',
      top: '5px',
      color: theme.palette.grey[500],
    },
  });

export default StreamerDialogStyles;
