import customAxios from '../../utils/customAxios';
import axios from 'axios';
import apiRoutes from '../../apiRoutes';
import reduxStore from '../../utils/reduxStore';

export const logout = () => {

    console.log('logout');

    if (reduxStore && reduxStore.getState() && reduxStore.getState().auth && reduxStore.getState().auth.user && reduxStore.getState().auth.user.operator) {
        if (reduxStore.getState().operatorStatus.status !== 'NOT_READY') {

            const operatorId = reduxStore.getState().auth.user.operator.id;
            const apiRoute = apiRoutes.OPERATORS + `/${operatorId}/not-ready`;

            axios.get(apiRoute, {
                headers: {
                    Authorization: `Bearer ${reduxStore.getState().auth.token}`
                }
            })
                .then((response) => {
                })
                .catch((error) => {
                });
            //operatorChangeStatus(reduxStore.getState().auth.user.operator.id, 'not-ready')(reduxStore.dispatch);
        }
    }

    localStorage.removeItem('token');
    localStorage.removeItem('tokenExpiration');
    localStorage.removeItem('user');


    return {
        type: 'AUTH_LOGOUT'
    };
};

export const checkAuthTimeout = (expiration) => {
    return (dispatch) => {
        setTimeout(() => {
            dispatch(logout());
        }, expiration * 1000);
    };
};


export const authCheckState = () => {
    return (dispatch) => {
        const token = localStorage.getItem('token');

        if (!token) {
            dispatch(authAutoFail());
            //dispatch(logout());
        } else {
            const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : '';
            const expirationDate = new Date(parseInt(localStorage.getItem('tokenExpiration')));

            if (expirationDate > new Date()) {
                dispatch(authAutoSuccess(token, user));
                dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime()) / 1000));

            } else {
                dispatch(logout());
            }
        }
    };
};


export const login = (name, password) => {

    return (dispatch) => {

        const authData = {email: name, password: password};

        dispatch(authStart());

        customAxios.post(apiRoutes.LOGIN, authData)
            .then((response) => {
                console.log(response);
                const expirationDate = new Date(new Date().getTime() + response.data.expires_in * 1000);
                const token = response.data.access_token;
                const user = response.data.user ? JSON.stringify(response.data.user) : "";
                localStorage.setItem('token', token);
                localStorage.setItem('user', user);
                localStorage.setItem('tokenExpiration', expirationDate.getTime().toString());
                localStorage.setItem('operator', 'OPERATOR');

                dispatch(authSuccess(response.data));


                //dispatch(checkAuthTimeout(response.data.expires));

            })
            .catch((error) => {
                dispatch(authFail(error.response.data.error));
            });
    };
};

export const mrLogin = (mrToken) => {

    return (dispatch) => {

        const authData = {mr_token: mrToken};

        dispatch(authStart(true));

        customAxios.post(apiRoutes.MR_LOGIN, authData)
            .then((response) => {
                console.log(response);
                const expirationDate = new Date(new Date().getTime() + response.data.expires_in * 1000);
                const token = response.data.access_token;
                const user = response.data.user ? JSON.stringify(response.data.user) : "";
                localStorage.setItem('token', token);
                localStorage.setItem('user', user);
                localStorage.setItem('tokenExpiration', expirationDate.getTime().toString());
                localStorage.setItem('operator', 'OPERATOR');

                dispatch(authSuccess(response.data, true));


                //dispatch(checkAuthTimeout(response.data.expires));

            })
            .catch((error) => {
                dispatch(authFail(error.response.data.error, true));
            });
    };
};

export const authStart = (mrToken) => {
    return mrToken ? {type: 'AUTH_MR_START'} : {type: 'AUTH_START'}
};

export const authSuccess = (payload, mrToken) => {
    return mrToken? {
        type: 'AUTH_MR_SUCCESS',
        payload: payload
    } : {
        type: 'AUTH_SUCCESS',
        payload: payload
    }
};

export const authAutoSuccess = (token, user) => {
    return {
        type: 'AUTH_AUTO_SUCCESS',
        token: token,
        user: user
    };
};

export const authAutoFail = () => {
    return {
        type: 'AUTH_AUTO_FAIL'
    };
};

export const authFail = (error, mrToken) => {
    return mrToken ? {
        type: 'AUTH_MR_FAIL',
        error: error
    } : {
        type: 'AUTH_FAIL',
        error: error
    }
};

export const authEraseError = () => {
    return {
        type: 'AUTH_ERASE_ERROR'
    };
};
