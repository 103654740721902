import React from 'react';
import moment from 'moment';
import MaterialDatePicker from "../../utils/MaterialDatePicker";
import Grid from "@material-ui/core/Grid";
import DashboardChart from "./DashboardChart";
import customAxios from "../../utils/customAxios";
import apiRoutes from "../../apiRoutes";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Zoom from "@material-ui/core/Zoom";
import Paper from "@material-ui/core/Paper";
import QualityChart from "./QualityChart";
import ChartInPaper from "./ChartInPaper";
import SubjectChart from "./SubjectChart";
import AvgDurationChart from "./AvgDurationChart";
import WithoutCameraChart from "./WithoutCameraChart";
import AbandonedCallsChart from './AbandonedCallsChart';
import ClientOsChart from './ClientOsChart';


class Dashboard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dateFrom: moment().subtract(14, 'days').valueOf(),
            dateTo: new Date(),
            charts: {
                totalCalls: {data: null, loading: false, error: null},
                avgQuality: {data: null, loading: false, error: null},
                avgDuration: {data: null, loading: false, error: null},
                //subjectRatio: {data: null, loading: false, error: null},
                withoutCamera: {data: null, loading: false, error: null},
                abandonedCalls: {data: null, loading: false, error: null},
                clientOS: {data: null, loading: false, error: null}
            }
        };

        this.handleDateChange = this.handleDateChange.bind(this);
        this.fetchReport = this.fetchReport.bind(this);
        this.submitHandler = this.submitHandler.bind(this);
    }

    componentDidMount() {

        this.fetchReport("totalCalls");
        this.fetchReport("avgQuality");
        this.fetchReport("avgDuration");
        //this.fetchReport("subjectRatio");
        this.fetchReport("withoutCamera");
        this.fetchReport("abandonedCalls");
        this.fetchReport("clientOS");
    }

    handleDateChange(field, val) {
        this.setState({[field]: val});
    }

    submitHandler(e) {
        e.preventDefault();

        this.fetchReport("totalCalls");
        this.fetchReport("avgQuality");
        this.fetchReport("avgDuration");
        //this.fetchReport("subjectRatio");
        this.fetchReport("withoutCamera");
        this.fetchReport("abandonedCalls");
        this.fetchReport("clientOS");
    }

    fetchReport(type) {

        this.setState((prevState) => {
            return {
                ...prevState,
                charts: {
                    ...prevState.charts,
                    [type]: {...prevState.charts[type], loading: true}
                }

            };
        });

        customAxios.post(apiRoutes.REPORTS, {
            from: moment(this.state.dateFrom).startOf('day').unix(),
            to: moment(this.state.dateTo).endOf('day').unix(),
            report: type
        })
            .then((res) => {
                this.setState((prevState) => {
                    return {
                        ...prevState,
                        charts: {
                            ...prevState.charts,
                            [type]: {data: res.data, loading: false, error: null}
                        }
                    };
                });
            })
            .catch((error) => {
                this.setState((prevState) => {
                    return {
                        ...prevState,
                        charts: {
                            ...prevState.charts,
                            [type]: {loading: false, error: error.response.error}
                        }
                    };
                });
            });
    }


    render() {
        return (
            <div style={{marginTop: '50px'}}>
                <Typography
                    align="center"
                    gutterBottom
                    variant="h4"
                    style={{fontSize: '24px'}}
                >
                    <span style={{color: '#37474f'}}>Дашборд</span>
                </Typography>
                {/*<div style={{textAlign: 'center', fontSize: '24px', marginTop: '50px'}}>Дашборд</div>*/}
                <Paper elevation={7} style={{margin: '10px'}}>
                    <Grid container alignItems="flex-end" justify="center" style={{marginTop: '20px'}}>

                        <Grid item xs={6} md={3} style={{padding: '10px'}}>
                            <MaterialDatePicker
                                label="От"
                                date={this.state.dateFrom}
                                handleDateChange={(date) => {
                                    this.handleDateChange('dateFrom', date)
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} md={3} style={{padding: '10px'}}>
                            <MaterialDatePicker
                                label="До"
                                date={this.state.dateTo}
                                handleDateChange={(date) => {
                                    this.handleDateChange('dateTo', date)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3} style={{padding: '10px'}}>
                            <Button
                                color="primary"
                                fullWidth
                                type="submit"
                                variant="contained"
                                style={{width: '100%', marginBottom: '10px'}}
                                onClick={this.submitHandler}
                            >
                                Обновить
                            </Button>
                        </Grid>

                    </Grid>
                </Paper>


                <Grid
                    container
                >
                    <ChartInPaper>
                        <DashboardChart
                            title="Общее количество звонков"
                            data={this.state.charts.totalCalls}
                            dataKeyX="repDate"
                            dataKeyY="callsCnt"
                            nameY="Кол-во"
                        />
                    </ChartInPaper>

                    <ChartInPaper>
                        <QualityChart
                            title="Качество"
                            data={this.state.charts.avgQuality}
                            dataKeyX="repDate"
                            dataKeyY="avgConsQuality"
                            nameY="Качество консультации"
                            dataKeyY2="avgVideoQuality"
                            nameY2="Качество видео"
                        />
                    </ChartInPaper>

                    {/*<ChartInPaper>*/}
                        {/*<SubjectChart*/}
                            {/*title="Темы обращений"*/}
                            {/*data={this.state.charts.subjectRatio}*/}
                        {/*/>*/}
                    {/*</ChartInPaper>*/}

                    <ChartInPaper>
                        <AvgDurationChart
                            title="Средняя продолжительность, с"
                            data={this.state.charts.avgDuration}
                        />
                    </ChartInPaper>

                    <ChartInPaper>
                        <WithoutCameraChart
                            title="Клиенты без включенной камеры, %"
                            data={this.state.charts.withoutCamera}
                        />
                    </ChartInPaper>
                    <ChartInPaper>
                        <AbandonedCallsChart
                            title="Пропущенные звонки"
                            data={this.state.charts.abandonedCalls}
                        />
                    </ChartInPaper>
                    <ChartInPaper>
                        <ClientOsChart
                            title="Операционные системы клиентов"
                            data={this.state.charts.clientOS}
                        />
                    </ChartInPaper>
                </Grid>
            </div>
        );
    }
}

export default Dashboard;