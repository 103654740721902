export default (state = {
    registration: null,
}, action) => {

    switch (action.type) {

        case 'SW_REGISTRATION_SET':
            return {...state, registration: action.payload };

        default:
            return state;

    }
}