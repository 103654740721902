import React from 'react';
import customAxios from "../../utils/customAxios";
import apiRoutes from "../../apiRoutes";
import CircularProgress from "@material-ui/core/CircularProgress";
import StartViewSku from "./StartViewSku";


class StartViewConsultationTimeChecker extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: null
        };

        this.fetchConsultationTime = this.fetchConsultationTime.bind(this);
    }

    componentDidMount() {
        this.fetchConsultationTime();
    }

    fetchConsultationTime() {
        this.setState({loading: true});

        customAxios.get(apiRoutes.CONSULTATION)
            .then((res) => {
                const {consultation_ability, consultation_start, consultation_end} = res.data.data;

                if (consultation_ability) {
                    this.setState({
                        loading: false
                    }, () => {
                        this.props.successHandler();
                    });
                } else {
                    this.setState({
                        loading: false,
                        error: `Сервіс працює з ${consultation_start} до ${consultation_end}`
                    }, () => {
                        window.setTimeout(() => {
                            this.props.failHandler()
                        }, 5000);
                    });
                }

            })
            .catch((error) => {
                this.setState({
                    loading: false
                }, () => {
                    window.setTimeout(() => {
                        this.props.failHandler()
                    }, 5000);
                });
            });
    }

    render() {
        return (
            <div style={{margin: '200px 0px', textAlign: 'center'}}>
                {this.state.error && (
                    <>
                        <div style={{fontSize: '18px'}}>
                            <div>{this.state.error}</div>
                            <div style={{marginTop: '15px'}}>Будемо раді Вас бачити в цей час</div>
                        </div>
                        <StartViewSku sku={this.props.sku}
                                      label={this.props.label}
                                      renderNull={true}
                                      clientData={this.props.clientData}
                        />
                    </>

                )}
                {this.state.loading && <CircularProgress size={35}/>}
            </div>
        );
    }
}

export default StartViewConsultationTimeChecker;