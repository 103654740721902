import React, {Component} from 'react';
import './StreamComponent.css';
import OvVideoComponent from './OvVideo';
import MicOff from '@material-ui/icons/MicOff';
import VideocamOff from '@material-ui/icons/VideocamOff';
import VolumeUp from '@material-ui/icons/VolumeUp';
import VolumeOff from '@material-ui/icons/VolumeOff';
import IconButton from '@material-ui/core/IconButton';
import HighlightOff from '@material-ui/icons/HighlightOff';
import logo from '../../images/allo_icon.png';
import ProductInfo from './ProductInfo';

class StreamComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {nickname: this.props.user.nickname, mutedSound: false};
        this.toggleSound = this.toggleSound.bind(this);
        this.renderLogoWithName = this.renderLogoWithName.bind(this);
    }

    toggleSound() {
        this.setState({mutedSound: !this.state.mutedSound});
    }

    renderLogoWithName() {
        if (this.props.isOperator && this.props.isLocalStream || !this.props.isOperator && !this.props.isLocalStream) {
            return (
                <img src={logo} style={{width: '17px', marginLeft: '7px', position: 'relative', top: '3px'}} alt=""/>);
        }
        return null;
    }

    render() {
        return (
            <div className="OT_widget-container">
                <div className="pointer nickname">
                    <span id="nickname">{this.props.user.nickname}{this.renderLogoWithName()}</span>
                </div>

                {this.props.user && this.props.user.streamManager ? (
                    <div className="streamComponent">
                        <OvVideoComponent user={this.props.user} mutedSound={this.state.mutedSound}/>
                        <div id="statusIcons">
                            {!this.props.user.videoActive ? (
                                <div id="camIcon">
                                    <VideocamOff id="statusCam"/>
                                </div>
                            ) : null}

                            {!this.props.user.audioActive ? (
                                <div id="micIcon">
                                    <MicOff id="statusMic"/>
                                </div>
                            ) : null}
                        </div>
                        <div>
                            {this.props.user.type !== 'local' && (
                                <IconButton id="volumeButton" onClick={this.toggleSound}>
                                    {this.state.mutedSound ? <VolumeOff color="inherit"/> : <VolumeUp/>}
                                </IconButton>
                            )}
                        </div>
                        <ProductInfo
                            sessionId={this.props.sessionId}
                            isOperator={this.props.isOperator}
                            userType={this.props.user.type}
                        />
                    </div>
                ) : null}
            </div>
        );
    }
}

export default StreamComponent;
