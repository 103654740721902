import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import { OpenVidu } from 'openvidu-browser';
import { MicOff, Mic } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import { setMediaAudio } from '../../actions/mediaDevices';
import { connect } from 'react-redux';

class StartViewAudioSelector extends React.Component {
    constructor(props) {
        super(props);

        this.OV = new OpenVidu();
        this.el = React.createRef();

        this.state = {
            permission: false,
            audioDevices: null,
            loading: false,
            error: null,
            selectedDevice: '',
            selectedDeviceBackup: null,
            disabled: true,
        };

        this.audioChangeHandler = this.audioChangeHandler.bind(this);
        this.setAudioDevicesToLocalState = this.setAudioDevicesToLocalState.bind(this);
        this.renderAudioDevices = this.renderAudioDevices.bind(this);
        this.renderIcon = this.renderIcon.bind(this);
        this.renderIconStyle = this.renderIconStyle.bind(this);
        this.toggleState = this.toggleState.bind(this);
    }

    componentDidMount() {
        navigator.mediaDevices
            .getUserMedia({
                audio: true,
                video: true,
            })
            .then((stream) => {
                stream.getTracks().forEach(function (track) {
                    track.stop();
                });
                this.setAudioDevicesToLocalState(() => {
                    this.setState(
                        {
                            permission: true,
                            disabled: false,
                        },
                        () => {
                            this.props.setMediaAudio({ permission: true });
                        },
                    );
                });
            })
            .catch(() => {
                console.log('permission denied');
            });
    }

    setAudioDevicesToLocalState(callback) {
        this.setState({ loading: true });

        this.OV.getDevices()
            .then((devices) => {
                console.log(devices);

                const audioDevices = devices.filter((device) => {
                    return device.kind === 'audioinput';
                });

                this.setState(
                    {
                        audioDevices: audioDevices,
                        loading: false,
                        selectedDevice: audioDevices[0].deviceId,
                    },
                    () => {
                        this.props.setMediaAudio({
                            audioDevices: audioDevices,
                            selectedDevice: audioDevices[0].deviceId,
                        });
                        if (callback) {
                            callback();
                        }
                    },
                );
            })
            .catch((error) => {
                this.setState({
                    audioDevices: null,
                    loading: false,
                    error: 'Ошибка доступа к микрофонам',
                });
            });
    }

    renderAudioDevices() {
        if (this.state.audioDevices && this.state.audioDevices.length > 0) {
            return this.state.audioDevices.map((audioDevice, i) => (
                <MenuItem key={i} value={audioDevice.deviceId}>
                    {audioDevice.label}
                </MenuItem>
            ));
        }
        return null;
    }

    audioChangeHandler(e) {
        // this.setState({selectedDevice: e.target.value});

        if (e.target.value === '') {
            this.setState({ selectedDevice: e.target.value }, () => {
                this.props.setMediaAudio({ selectedDevice: this.state.selectedDevice });
                window.setTimeout(() => {
                    this.el.children[0].blur();
                    this.setState({ disabled: true });
                }, 100);
            });
        } else {
            this.setState({ selectedDevice: e.target.value, disabled: false }, () => {
                this.props.setMediaAudio({ selectedDevice: this.state.selectedDevice });
            });
        }
    }

    toggleState() {
        if (!this.state.permission) {
            navigator.mediaDevices
                .getUserMedia({
                    audio: true,
                })
                .then(() => {
                    this.setAudioDevicesToLocalState(() => {
                        this.setState(
                            {
                                permission: true,
                                disabled: false,
                            },
                            () => {
                                this.props.setMediaAudio({ permission: true });
                            },
                        );
                    });
                })
                .catch(() => {
                    console.log('permission denied');
                });
            return;
        }

        this.setState(
            (prevState) => {
                let selectedDeviceBackup = prevState.selectedDeviceBackup;
                let selectedDevice = prevState.selectedDevice;

                if (!prevState.disabled) {
                    if (prevState.selectedDevice) {
                        selectedDeviceBackup = prevState.selectedDevice;
                        selectedDevice = '';
                    }
                } else {
                    if (prevState.selectedDeviceBackup) {
                        selectedDevice = prevState.selectedDeviceBackup;
                        selectedDeviceBackup = null;
                    } else {
                        selectedDevice = this.state.audioDevices[0].deviceId;
                    }
                }

                return {
                    ...prevState,
                    disabled: !prevState.disabled,
                    selectedDevice: selectedDevice,
                    selectedDeviceBackup: selectedDeviceBackup,
                };
            },
            () => {
                this.props.setMediaAudio({ selectedDevice: this.state.selectedDevice });
            },
        );
    }

    renderIcon() {
        return (
            <IconButton color="inherit" style={this.renderIconStyle()} onClick={this.toggleState}>
                {this.state.disabled ? <MicOff /> : <Mic color="primary" />}
            </IconButton>
        );
    }

    renderIconStyle() {
        return {
            position: 'absolute',
            top: '5px',
            left: '-60px',
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
        };
    }

    render() {
        return (
            <div>
                <FormControl style={{ width: '100%', margin: '15px 0px', position: 'relative' }}>
                    <InputLabel>Мікрофон</InputLabel>
                    <Select
                        value={this.state.selectedDevice}
                        onChange={this.audioChangeHandler}
                        disabled={this.state.disabled}
                        ref={(el) => {
                            this.el = el;
                        }}>
                        <MenuItem value="">Відключити мікрофон</MenuItem>
                        {this.renderAudioDevices()}
                    </Select>
                    {this.renderIcon()}
                </FormControl>
            </div>
        );
    }
}

export default connect(null, { setMediaAudio })(StartViewAudioSelector);
