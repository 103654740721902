import React from 'react';
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import ShopIcon from '@material-ui/icons/Shop';
import customAxios from "../../utils/customAxios";
import apiRoutes from "../../apiRoutes";
import CircularProgress from "@material-ui/core/CircularProgress";
import {setProduct} from "../../actions/clientData";
import {connect} from "react-redux";

class StartViewSku extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            product: null,
            loading: false,
            error: null
        };

        this.renderIcon = this.renderIcon.bind(this);
        this.renderIconStyle = this.renderIconStyle.bind(this);
        this.fetchProduct = this.fetchProduct.bind(this);
    }

    componentDidMount() {
        if(!this.props.clientData.product){
            this.fetchProduct();
        }
    }

    fetchProduct() {
        this.setState({loading: true});

        customAxios.get(apiRoutes.PRODUCT + `/${this.props.sku}`)
            .then((res) => {
                console.log(res.data);
                this.setState({
                    product: res.data,
                    loading: false
                }, () => {
                    this.props.setProduct(this.state.product)
                });
            })
            .catch((error) => {
                this.setState({loading: false});
            });
    }

    renderIcon() {
        return (
            <IconButton color="inherit" style={this.renderIconStyle()}>
                <ShopIcon color='primary'/>
            </IconButton>
        );
    }

    renderIconStyle() {
        return {
            position: 'absolute',
            top: '5px',
            left: '-60px',
            backgroundColor: 'rgba(0, 0, 0, 0.05)'
        };
    }

    render() {
        if (this.props.renderNull) {
            return null;
        } else {
            return (
                <div>
                    <FormControl style={{width: '100%', margin: '15px 0px', position: 'relative'}}>
                        <TextField
                            fullWidth
                            label={this.props.label}
                            type="text"
                            value={this.state.product ? this.state.product.name : ''}
                            style={{width: '100%'}}
                            // variant="outlined"
                        />
                        {this.renderIcon()}
                    </FormControl>
                </div>
            );
        }
    }

}

export default connect(null, {setProduct})(StartViewSku);