import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Slide from '@material-ui/core/Slide';

import customAxios from '../../utils/customAxios';
import apiRoutes from '../../apiRoutes';

//styles
import StreamerDialogStyles from './StreamerDialogStyles';

const DialogTitle = withStyles(StreamerDialogStyles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class AddStreamer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dialogOpen: false,
      name: '',
      email: '',
      employee_code: '',
      displayName: '',
      submitLoading: false,
      submitErrors: {},
    };

    this.handleDialogOpen = this.handleDialogOpen.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.submitHandler = this.submitHandler.bind(this);
    this.submitToApi = this.submitToApi.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleValid = this.handleValid.bind(this);
  }

  handleDialogOpen = () => {
    this.setState({ dialogOpen: true });
  };

  handleDialogClose = () => {
    this.setState({ dialogOpen: false });
  };

  submitHandler(e) {
    e.preventDefault();
    if (this.state.submitLoading) return;
    if (this.state.submitErrors.name || this.state.submitErrors.email) return;

    this.submitToApi(this.props);
  }

  handleFieldChange(event, field) {
    event.persist();

    this.setState((prevState) => {
      if (this.state.submitErrors && this.state.submitErrors[field]) {
        const { submitErrors } = this.state;
        delete submitErrors[field];
        return { ...prevState, submitErrors, [field]: event.target.value };
      } else {
        return { ...prevState, [field]: event.target.value };
      }
    });
  }

  handleValid() {
    if (this.state.name.trim() !== '' && this.state.email.trim() !== '') {
      return true;
    }
    return false;
  }

  renderError(field) {
    if (this.state.submitErrors && this.state.submitErrors[field]) {
      return (
        <div className="" style={{ color: 'red', marginTop: '15px' }}>
          {this.state.submitErrors[field]}
        </div>
      );
    }
    return null;
  }

  submitToApi({ fetchStreamersFromApi }) {
    this.setState({ submitLoading: true });

    customAxios
      .post(apiRoutes.OPERATORS, {
        name: this.state.name,
        email: this.state.email,
        displayName: this.state.displayName,
        employee_code: this.state.employee_code,
      })
      .then((res) => {
        this.setState(
          {
            submitLoading: false,
            name: '',
            displayName: '',
            email: '',
            employee_code: '',
            submitErrors: {},
            dialogOpen: false,
          },
          () => {
            window.setTimeout(() => {
              alert('Стример создан. Пароль: ' + res.data.password);
              fetchStreamersFromApi();
            }, 500);
          },
        );
      })
      .catch((error) => {
        this.setState({ submitLoading: false });
      });
  }

  render() {
    const { classes } = this.props;

    let buttonText = 'Добавить';

    if (this.state.submitLoading) {
      buttonText = (
        <React.Fragment>
          <CircularProgress size={25} style={{ color: 'white' }} />
          <span style={{ marginLeft: '10px' }}>Добавить</span>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          <Button color="primary" variant="contained" onClick={this.handleDialogOpen}>
            <PersonAddIcon />
          </Button>
        </div>
        <Dialog
          TransitionComponent={Transition}
          fullWidth={true}
          maxWidth="sm"
          open={this.state.dialogOpen}
          onClose={this.handleDialogClose}>
          <DialogTitle className={classes.title} onClose={this.handleDialogClose}>
            Добавление стримера
          </DialogTitle>

          <DialogContent>
            <form className={classes.form} noValidate>
              <TextField
                fullWidth
                label="Имя"
                variant="outlined"
                className={classes.textField}
                value={this.state.name}
                onChange={(e) => {
                  this.handleFieldChange(e, 'name');
                }}
              />
              <TextField
                fullWidth
                label="Отображаемое имя"
                variant="outlined"
                className={classes.textField}
                value={this.state.display_name}
                onChange={(e) => {
                  this.handleFieldChange(e, 'displayName');
                }}
              />
              {this.renderError('name')}
              <TextField
                fullWidth
                label="Email"
                variant="outlined"
                className={classes.textField}
                value={this.state.email}
                onChange={(e) => {
                  this.handleFieldChange(e, 'email');
                }}
              />
              {this.renderError('email')}
              <TextField
                fullWidth
                label="Код сотрудника"
                variant="outlined"
                className={classes.textField}
                value={this.state.employee_code}
                onChange={(e) => {
                  this.handleFieldChange(e, 'employee_code');
                }}
              />
              {this.renderError('employee_code')}
              <Button
                className={classes.signInButton}
                color="primary"
                fullWidth
                type="submit"
                disabled={!this.handleValid()}
                variant="contained"
                style={{ width: '100%' }}
                onClick={this.submitHandler}>
                {buttonText}
              </Button>
            </form>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default withStyles(StreamerDialogStyles)(AddStreamer);
