import React from "react";
import {Button, Zoom} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    root: {
        color: 'white',
        width: '280px',
        height: '50px',
        fontSize: '16px',
        fontWeight: '800',
        // backgroundColor: 'red',
        // '&:hover': {
        //     backgroundColor: 'red',
        // },
    },
}));

export default function SubmitButton(props) {

    const classes = useStyles();

    return (
        <Zoom in={true}>
            <Button
                variant="contained"
                color="primary"
                disabled={false}
                className={classes.root}
                onClick={props.handleClickSubmit}
            >{props.name}</Button>
        </Zoom>
    );
}



