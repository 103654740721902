import React from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router";
import moment from 'moment';
import {store} from "react-notifications-component";

import {withStyles} from "@material-ui/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Zoom from "@material-ui/core/Zoom";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import customAxios from "../utils/customAxios";
import apiRoutes from "../apiRoutes";
import OpenviduHelper from "../utils/OpenviduHelper";
import {setLoadingOVToken, setOVToken, unsetLoadingOVToken} from "../actions/openvidu";


const useStyles = theme => ({
    root: {
        paddingTop: '40px',
        paddingBottom: '20px'
    },
    grid: {
        height: '100%'
    },
    name: {
        marginTop: theme.spacing(3),
        color: theme.palette.white
    },
    contentContainer: {},
    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    logoImage: {
        marginLeft: theme.spacing(4)
    },
    title: {
        textAlign: 'center',
        margin: '20px 0px'
    },
    contentBody: {
        paddingLeft: '10px',
        paddingRight: '10px'
    },
    form: {
        paddingLeft: 100,
        paddingRight: 100,
        paddingTop: 20,
        paddingBottom: 50,
        flexBasis: 700,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2)
        }
    },
    signInButton: {
        marginTop: '50px',
        paddingTop: '15px',
        paddingBottom: '15px',
        fontSize: '18px'
    },
    notchedOutline: {
        borderWidth: '1px',
        borderColor: 'yellow !important'
    }
});

class CallInfo extends React.Component {

    constructor(props) {
        super(props);

        this.openviduHelper = new OpenviduHelper();
        this.sessionId = this.props.match.params.id ? this.props.match.params.id : null;
        this.renderNotification = this.renderNotification.bind(this);

        this.state = {
            callInfo: null,
            loading: false,
            error: null
        };

        this.fetchCallInfo = this.fetchCallInfo.bind(this);
        this.handleButtonClick = this.handleButtonClick.bind(this);
        this.getCreatedAt = this.getCreatedAt.bind(this);
        this.renderBody = this.renderBody.bind(this);
        this.renderButton = this.renderButton.bind(this);
    }

    componentDidMount() {
        if (this.sessionId) {
            this.fetchCallInfo(this.sessionId);
        }
    }

    fetchCallInfo(sessionId) {
        this.setState({loading: true});

        customAxios.get(apiRoutes.CALL_INFO + `/${sessionId}`)
            .then((res) => {

                this.setState({
                    callInfo: res.data.data,
                    loading: false
                });
            })
            .catch(() => {
                this.setState({loading: false});
            });
    }

    async handleButtonClick(sessionId) {
        try {
            this.props.setLoadingOVToken();
            const tokenObj = await this.openviduHelper.createTokenWithSessionId(sessionId);
            this.props.setOVToken(tokenObj.token);
            this.props.history.push(`/call/${sessionId}`);
        } catch (e) {
            this.props.unsetLoadingOVToken();
            this.renderNotification(e.response.data.error, true);
        }
    }

    renderNotification(message, error) {
        store.addNotification({
            title: `Входящий звонок`,
            message: message,
            type: error ? 'danger' : 'success',
            container: 'top-right',
            animationIn: ["animated", "slideInLeft"],
            animationOut: ["animated", "slideOutRight"],
            dismiss: {
                duration: 120000,
                showIcon: true
            },
            width: 350
        });
    }

    getCreatedAt() {
        return moment
            .unix(this.state.callInfo.created_at)
            .format('DD.MM.YYYY HH:mm:ss');
    }

    renderButton(classes) {
        if (this.state.callInfo.status === 'NEW') {
            return (
                <Button
                    className={classes.signInButton}
                    color="primary"
                    fullWidth
                    type="submit"
                    variant="contained"
                    style={{width: '100%', height: '61px'}}
                >
                    Принять
                </Button>
            );
        }

        return null;
    }

    renderBody() {

        const {callInfo} = this.state;

        if (this.state.loading) {
            return (
                <div style={{textAlign: 'center', margin: '50px 0px'}}>
                    <CircularProgress size={35}/>
                </div>
            );
        } else if (callInfo) {

            const {classes} = this.props;

            return (
                <React.Fragment>
                    <div style={{margin: '10px 0px'}}>Статус: {callInfo.status}</div>
                    <div style={{margin: '10px 0px'}}>Время поступления: {this.getCreatedAt()}</div>
                    <div style={{margin: '10px 0px'}}>Имя
                        клиента: {callInfo.client ? callInfo.client.client_name : ''}</div>
                    {/*<div style={{margin: '10px 0px'}}>Причина*/}
                        {/*обращения: {callInfo.subject ? callInfo.subject.title : ''}</div>*/}
                    <div style={{margin: '10px 0px'}}>Товар: sku ({callInfo.sku}) {callInfo.product_name}</div>
                    {/*<CallInfoProduct sku={this.state.callInfo.sku}/>*/}
                    <Grid
                        container
                    >
                        <Grid
                            item
                            sm={2}
                            md={3}
                            lg={4}
                        >
                        </Grid>
                        <Grid
                            className={classes.content}
                            item
                            xs={12}
                            sm={8}
                            md={6}
                            lg={4}
                        >
                            {this.renderButton(classes)}
                        </Grid>
                    </Grid>
                </React.Fragment>
            );
        }
        return null
    }

    render() {

        const {classes} = this.props;

        return (
            <div className="container" id="container">

                <div className={classes.root}>
                    <Grid
                        className={classes.grid}
                        container
                    >
                        <Grid
                            className={classes.content}
                            item
                            xs={12}
                        >
                            <div className={classes.contentBody}>
                                <Zoom in={true}>
                                    <Paper elevation={5}>
                                        <form
                                            className={classes.form}
                                            onSubmit={async (e) => {
                                                e.preventDefault();
                                                await this.handleButtonClick(this.sessionId)
                                            }}
                                        >
                                            <Typography
                                                className={classes.title}
                                                variant="h4"
                                            >
                                                Информация о звонке {this.sessionId}
                                            </Typography>

                                            {this.renderBody()}
                                        </form>
                                    </Paper>
                                </Zoom>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        );
    }
}

export default connect(null, {
    setOVToken,
    setLoadingOVToken,
    unsetLoadingOVToken
})(withRouter(withStyles(useStyles)(CallInfo)));