import React from "react";
import {Button, Zoom} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';


const useStyles = makeStyles(() => ({
    root: {
        marginTop: '30px',
        color: 'white',
        width: '280px',
        height: '50px',
        fontSize: '16px',
        fontWeight: '800',
        // backgroundColor: 'blue',
        // '&:hover': {
        //     backgroundColor: 'blue',
        // },
    },
}));

export default function SkipButton(props) {

    const classes = useStyles();

    return (
        <Zoom in={true}>
            <Button
                variant="contained"
                color="secondary"
                disabled={false}
                className={classes.root}
                onClick={props.skipAnswer}
            >{props.text}</Button>
        </Zoom>
    );
}



