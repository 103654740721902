export default (state = {
    clientName: '',
    clientId: null,
    clientQuestion: null,
    authorizedClient: false,
    product: null,
    recordingConsent: true
}, action) => {

    switch (action.type) {

        case 'CLIENT_DATA_CLIENTNAME_SET':
            return {...state, clientName: action.clientName};

        case 'CLIENT_DATA_CLIENTQUESTION_SET':
            return {...state, clientQuestion: action.payload};

        case 'CLIENT_DATA_AUTHORIZED_CLIENT_SET':
            return {...state, authorizedClient: action.payload};

        case 'CLIENT_DATA_PRODUCT_SET':
            return {...state, product: action.payload };

        case 'CLIENT_DATA_TOGGLE_RECORDING_CONSENT':
            return {...state, recordingConsent: !state.recordingConsent};
        case 'CLIENT_DATA_CLIENTID_SET':
            return {...state, clientId: action.clientId};

        case 'CLIENT_DATA_UNSET':
            return {
                clientName: '',
                product: null
            };

        default:
            return state;

    }
}