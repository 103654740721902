export default {
  body: {
    emptyDataSourceMessage: ""
  },
  toolbar: {
    searchTooltip: "Поиск",
    searchPlaceholder: "Поиск",
    exportTitle: "Экспорт ключей",
    exportAriaLabel: "Экспорт ключей",
    exportName: "Экспортировать в CSV"
  },
  pagination: {
    labelDisplayedRows: "{from}-{to} из {count}",
    labelRowsSelect: "строк",
    labelRowsPerPage: "Строк на страницу",
    firstAriaLabel: "Первая страница",
    firstTooltip: "Первая страница",
    previousAriaLabel: "Предыдущая страница",
    previousTooltip: "Предыдущая страница",
    nextAriaLabel: "Следующая страница",
    nextTooltip: "Следующая страница",
    lastAriaLabel: "Последняя страница",
    lastTooltip: "Последняя страница",
  },
  header: {
    actions: ''
  },
}