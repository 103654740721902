import React from 'react';
import moment from 'moment';

import materialTableLocalization from '../../utils/materialTableLocalization';
import MaterialTable from 'material-table';
import Zoom from '@material-ui/core/Zoom';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/styles';

import customAxios from '../../utils/customAxios';
import apiRoutes from '../../apiRoutes';
import AddStreamer from './AddStreamer';
import DeleteStreamer from './DeleteStreamer';
import UpdateStreamerData from './UpdateStreamerData';

const useStyles = (theme) => ({
  root: {},
  grid: {
    height: '100%',
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white,
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  contentBody: {
    marginTop: '50px',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
});

class StreamersView extends React.Component {
  constructor(props) {
    super(props);

    this.intervalHandle = null;

    this.state = {
      streamers: [],
      loading: false,
      error: null,
      deleteDialogOpen: false,
      deleteStreamer: null,
      updateDialogOpen: false,
      updateStreamer: null,
    };

    this.statusColors = {
      default: 'rgb(38, 50, 56)',
      ready: '#43a047',
      notReady: '#e53935',
      inCall: '#1e88e5',
    };

    this.streamerStatuses = {
      ready: 'READY',
      notReady: 'NOT_READY',
      inCall: 'IN_CALL',
    };

    this.renderStreamersTable = this.renderStreamersTable.bind(this);
    this.getTransformedStreamersFromState = this.getTransformedStreamersFromState.bind(this);
    this.fetchStreamersFromApi = this.fetchStreamersFromApi.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.deleteDialogToggle = this.deleteDialogToggle.bind(this);

    this.handleUpdateClick = this.handleUpdateClick.bind(this);
    this.updateDialogToggle = this.updateDialogToggle.bind(this);
  }

  componentDidMount() {
    this.fetchStreamersFromApi();

    this.intervalHandle = window.setInterval(this.fetchStreamersFromApi, 30000);
  }

  componentWillUnmount() {
    window.clearInterval(this.intervalHandle);
  }

  handleDeleteClick(streamer) {
    this.setState({ deleteStreamer: streamer, deleteDialogOpen: true });
  }

  handleUpdateClick(streamer) {
    this.setState({ updateStreamer: streamer, updateDialogOpen: true });
  }

  fetchStreamersFromApi() {
    this.setState({ loading: true });

    customAxios
      .get(apiRoutes.OPERATORS)
      .then((res) => {
        this.setState({
          streamers: res.data ? res.data : [],
          loading: false,
          error: null,
        });
      })
      .catch((error) => {
        this.setState({ loading: false, error: 'Ошибка получения стримеров' });
      });
  }

  getTransformedStreamersFromState() {
    const streamers = this.state.streamers.map((streamer) => {
      const modifiedCreatedAt = moment(streamer.created_at).format('DD.MM.YYYY');

      return {
        ...streamer,
        created_at: modifiedCreatedAt,
      };
    });

    return streamers;
  }

  renderStreamersTable() {
    if (this.state.error) {
      return (
        <div
          style={{
            textAlign: 'center',
            marginTop: '100px',
            marginBottom: '100px',
            padding: '50px 10px',
          }}>
          {this.state.error}
        </div>
      );
    } else {
      return (
        <MaterialTable
          isLoading={this.state.loading}
          columns={[
            { title: 'ID', field: 'id', editable: 'never' },
            { title: 'Имя', field: 'user.name' },
            { title: 'Отображаемое имя', field: 'user.display_name' },
            { title: 'Email', field: 'user.email', editable: 'never' },
            {
              title: 'Статус',
              field: 'status',
              lookup: {
                [this.streamerStatuses.ready]: this.streamerStatuses.ready,
                [this.streamerStatuses.notReady]: this.streamerStatuses.notReady,
                [this.streamerStatuses.inCall]: this.streamerStatuses.inCall,
              },
              cellStyle: (cellData) => {
                let statusColor = this.statusColors.default;

                if (cellData === this.streamerStatuses.ready) {
                  statusColor = this.statusColors.ready;
                } else if (cellData === this.streamerStatuses.notReady) {
                  statusColor = this.statusColors.notReady;
                } else if (cellData === this.streamerStatuses.inCall) {
                  statusColor = this.statusColors.inCall;
                }
                return { color: statusColor };
              },
              editable: 'never',
            },
            { title: 'Зарегистрирован', field: 'created_at', editable: 'never' },
          ]}
          data={this.getTransformedStreamersFromState()}
          actions={[
            {
              icon: 'edit',
              tooltip: 'Редактировать данные стримера',
              onClick: (event, rowData) => {
                this.handleUpdateClick(rowData);
              },
            },
            {
              icon: 'delete',
              tooltip: 'Удалить стримера',
              onClick: (event, rowData) => {
                this.handleDeleteClick(rowData);
              },
            },
          ]}
          localization={materialTableLocalization}
          title=""
          options={{
            actionsColumnIndex: -1,
            pageSize: 100,
            pageSizeOptions: [20, 50, 100],
            emptyRowsWhenPaging: false,
            exportButton: false,
            filtering: true,
          }}
        />
      );
    }
  }

  deleteDialogToggle(newState) {
    this.setState({ deleteDialogOpen: newState });
  }

  updateDialogToggle(newState) {
    this.setState({ updateDialogOpen: newState });
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <div className={classes.root}>
          <Grid className={classes.grid} container>
            <Grid className={classes.content} item xs={12}>
              <div className={classes.content}>
                <div className={classes.contentBody}>
                  <Typography align="center" gutterBottom variant="h4" style={{ fontSize: '24px' }}>
                    <span style={{ color: '#37474f' }}>Стримеры</span>
                  </Typography>

                  <AddStreamer fetchStreamersFromApi={this.fetchStreamersFromApi} />

                  <DeleteStreamer
                    deleteDialogOpen={this.state.deleteDialogOpen}
                    deleteDialogToggle={this.deleteDialogToggle}
                    deleteStreamer={this.state.deleteStreamer}
                    fetchStreamersFromApi={this.fetchStreamersFromApi}
                  />

                  {this.state.updateDialogOpen && (
                    <UpdateStreamerData
                      openDialog={this.state.updateDialogOpen}
                      toggleDialog={this.updateDialogToggle}
                      updateStreamer={this.state.updateStreamer}
                      fetchStreamersFromApi={this.fetchStreamersFromApi}
                    />
                  )}

                  <Zoom in={true}>
                    <Paper elevation={7}>
                      <div style={{ maxWidth: '100%', marginTop: '30px' }}>
                        {this.renderStreamersTable()}
                      </div>
                    </Paper>
                  </Zoom>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

export default withStyles(useStyles)(StreamersView);
