import React from 'react';
import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LineChart
} from 'recharts';
import moment from 'moment';
import CircularProgress from "@material-ui/core/CircularProgress";
import Line from "recharts/es6/cartesian/Line";

class QualityChart extends React.Component {

    constructor(props) {
        super(props);

        this.getTransformedData = this.getTransformedData.bind(this);
        this.renderChartBody = this.renderChartBody.bind(this);
    }

    getTransformedData(data) {
        return data.map((item) => {
            return {
                ...item,
                repDate: moment.unix(item.repDate).format('DD.MM'),
                avgConsQuality: parseFloat(item.avgConsQuality).toFixed(2),
                avgVideoQuality: parseFloat(item.avgVideoQuality).toFixed(2),
            };
        });
    }

    renderChartBody(state) {

        const { dataKeyX, dataKeyY, dataKeyY2, nameY, nameY2 } = this.props;

        if (state.loading) {
            return (
                <div style={{margin: '50px', textAlign: 'center'}}>
                    <CircularProgress size={35}/>
                </div>
            );
        } else if (state.error) {
            return (
                <div className="">
                    Ошибка получения данных
                </div>
            );
        } else if (state.data) {
            return (
                <ResponsiveContainer width="100%" height={300}>
                    <LineChart data={this.getTransformedData(state.data)}
                              margin={{ top: 5, right: 10, bottom: 5, left: -25 }}
                    >
                        <CartesianGrid strokeDasharray="3 3"/>
                        <XAxis dataKey={dataKeyX}/>
                        <YAxis/>
                        <Tooltip/>
                        <Legend/>
                        <Line type="monotone" dataKey={dataKeyY} name={nameY} stroke="#E33638"/>
                        <Line type="monotone" dataKey={dataKeyY2} name={nameY2} stroke="#4CA746"/>
                    </LineChart>
                </ResponsiveContainer>
            );
        }
        return null;
    }


    render() {
        if (this.props.data) {
            return (
                <div className="">
                    <h4 style={{textAlign: 'center'}}>{this.props.title}</h4>
                    {this.renderChartBody(this.props.data)}
                </div>
            );
        }
        return null;
    }
}

export default QualityChart;