import React from 'react';
import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LineChart, Line
} from 'recharts';
import moment from 'moment';
import CircularProgress from "@material-ui/core/CircularProgress";

class SubjectChart extends React.Component {

    constructor(props) {
        super(props);

        this.getTransformedData = this.getTransformedData.bind(this);
        this.renderChartBody = this.renderChartBody.bind(this);
        this.getBarName = this.getBarName.bind(this);
    }

    getTransformedData(data) {
        return data.map((item) => {
            return {
                ...item,
                repDate: moment.unix(item.repDate).format('DD.MM'),
                ratio: item.ratio.map((ratioItem) => {
                    return {
                        ...ratioItem,
                        percent: parseFloat(ratioItem.percent * 100).toFixed(2)
                    };
                })
            };
        });
    }

    getBarName(subjectId){
        return this.props.data.data.subjects.filter((subject) => {
            return subjectId === subject.id;
        })[0].title;
    }

    renderChartBody(state) {

        if (state.loading) {
            return (
                <div style={{margin: '50px', textAlign: 'center'}}>
                    <CircularProgress size={35}/>
                </div>
            );
        } else if (state.error) {
            return (
                <div className="">
                    Ошибка получения данных
                </div>
            );
        } else if (state.data) {
            return (
                <ResponsiveContainer width="100%" height={300}>
                    <BarChart data={this.getTransformedData(state.data.data)}
                               margin={{ top: 5, right: 10, bottom: 5, left: -25 }}
                    >
                        <CartesianGrid strokeDasharray="3 3"/>
                        <XAxis dataKey="repDate"/>
                        <YAxis/>
                        <Tooltip/>
                        <Legend/>
                        <Bar dataKey="ratio[1].percent" unit="%" name={this.getBarName(1)} fill="#4CA746"/>
                        <Bar dataKey="ratio[0].percent" unit="%" name={this.getBarName(2)} fill="#E33638"/>
                    </BarChart>
                </ResponsiveContainer>
            );
        }
        return null;
    }


    render() {
        if (this.props.data) {
            return (
                <div className="">
                    <h4 style={{textAlign: 'center'}}>{this.props.title}</h4>
                    {this.renderChartBody(this.props.data)}
                </div>
            );
        }
        return null;
    }
}

export default SubjectChart;