import customAxios from './customAxios';
import axios from 'axios';
import apiRoutes from '../apiRoutes';

class OpenviduHelper {
    getToken() {
        // return this.createToken();
        //return this.createSession().then((sessionId) => this.createToken(sessionId));
    }

    createSession() {
        return new Promise((resolve, reject) => {
            // const data = JSON.stringify({ customSessionId: sessionId });
            customAxios
                .post(apiRoutes.SESSIONS)
                .then((response) => {
                    console.log('CREATE SESION ', response.data.id);
                    resolve(response.data.id);
                })
                .catch((response) => {
                    const error = Object.assign({}, response);
                    if (error.response && error.response.status === 409) {
                        resolve();
                    } else {
                        console.log(error);
                    }
                });
        });
    }

    createTokenWithSessionId(sessionId) {
        return new Promise((resolve, reject) => {
            let data = {};

            if (sessionId) {
                data = { session: sessionId };
            }

            customAxios
                .post(apiRoutes.TOKENS, data)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => reject(error));
        });
    }

    createTokenForClient(clientData) {
        return new Promise((resolve, reject) => {
            let data = { data: clientData };

            axios
                .post(apiRoutes.TOKENS, data)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => reject(error));
        });
    }

    async leaveSession(sessionId) {
        try {
            const res = await customAxios.post(apiRoutes.SESSIONS + `/${sessionId}/leave`);
        } catch (e) {
            console.log(e);
        }
    }
}

export default OpenviduHelper;
