import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';

import MaterialTable from 'material-table';
import Zoom from '@material-ui/core/Zoom';
import materialTableLocalization from '../utils/materialTableLocalization';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';

import customAxios from '../utils/customAxios';
import apiRoutes from '../apiRoutes';
import OpenviduHelper from '../utils/OpenviduHelper';
import { setLoadingOVToken, setOVToken, unsetLoadingOVToken } from '../actions/openvidu';

const useStyles = (theme) => ({
    root: {},
    grid: {
        height: '100%',
    },
    name: {
        marginTop: theme.spacing(3),
        color: theme.palette.white,
    },
    contentContainer: {},
    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    contentBody: {
        marginTop: '50px',
        paddingLeft: '10px',
        paddingRight: '10px',
    },
});

class ActiveCalls extends React.Component {
    constructor(props) {
        super(props);

        this.intervalHandle = null;
        this.openviduHelper = new OpenviduHelper();

        this.state = {
            activeSessions: [],
            loading: false,
            error: null,
        };

        this.fetchActiveSessionsFromApi = this.fetchActiveSessionsFromApi.bind(this);
        this.renderActiveCallsTable = this.renderActiveCallsTable.bind(this);
        this.getTransformedActiveSessionsFromState = this.getTransformedActiveSessionsFromState.bind(
            this,
        );
        this.handleLinkClick = this.handleLinkClick.bind(this);
    }

    filterActiveCalls(calls) {
        const permission = this.props.permissions.includes('viewCalls');

        if (!permission) {
            return calls.filter((call) => call.status === 'NEW');
        } else {
            return calls;
        }
    }

    fetchActiveSessionsFromApi() {
        this.setState({ loading: true });
        customAxios
            .get(apiRoutes.SESSIONS)
            .then((res) => {
                this.setState({
                    activeSessions: res.data ? this.filterActiveCalls(res.data) : [],
                    loading: false,
                    error:
                        (res.data && res.data.length === 0) ||
                        this.filterActiveCalls(res.data).length === 0
                            ? 'На данный момент нет активных звонков'
                            : null,
                });
            })
            .catch((error) => {
                console.log(error);
                this.setState({ loading: false });
            });
    }

    getTransformedActiveSessionsFromState() {
        const activeSessions = this.state.activeSessions.map((activeSession) => {
            const modifiedCreatedAt = moment.unix(activeSession.created_at).format('HH:mm:ss');

            return {
                ...activeSession,
                createdAt: modifiedCreatedAt,
            };
        });
        return activeSessions;
    }

    handleLinkClick(sessionId) {
        this.props.history.push(`/call-info/${sessionId}`);
    }

    componentDidMount() {
        this.fetchActiveSessionsFromApi();
        this.intervalHandle = window.setInterval(this.fetchActiveSessionsFromApi, 10000);
    }

    componentWillUnmount() {
        window.clearInterval(this.intervalHandle);
    }

    renderActiveCallsTable() {
        if (this.state.error) {
            return (
                <div
                    style={{
                        textAlign: 'center',
                        marginTop: '100px',
                        marginBottom: '100px',
                        padding: '50px 10px',
                    }}>
                    {this.state.error}
                </div>
            );
        } else {
            return (
                <MaterialTable
                    isLoading={this.state.loading}
                    columns={[
                        { title: 'Статус', field: 'status', width: '40px' },
                        { title: 'Время', field: 'createdAt', width: '40px' },
                        { title: 'Товар', field: 'product_name' },
                        { title: 'SKU', field: 'sku' },
                        // {title: 'Причина обращения', field: 'subject.title'},
                        { title: 'Имя клиента', field: 'clientName' },
                    ]}
                    data={this.getTransformedActiveSessionsFromState()}
                    actions={[
                        {
                            icon: 'link',
                            tooltip: 'Подключиться к звонку',
                            onClick: (event, rowData) => {
                                this.handleLinkClick(rowData.session_id);
                            },
                        },
                    ]}
                    localization={materialTableLocalization}
                    title=""
                    options={{
                        pageSize: 10,
                        pageSizeOptions: [10, 20, 50],
                        emptyRowsWhenPaging: false,
                        exportButton: false,
                    }}
                />
            );
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <div>
                <div className={classes.root}>
                    <Grid className={classes.grid} container>
                        <Grid className={classes.content} item xs={12}>
                            <div className={classes.content}>
                                <div className={classes.contentBody}>
                                    <Typography
                                        align="center"
                                        gutterBottom
                                        variant="h4"
                                        style={{ fontSize: '24px' }}>
                                        <span style={{ color: '#37474f' }}>Активные звонки</span>
                                    </Typography>

                                    <Zoom in={true}>
                                        <Paper elevation={7}>
                                            <div
                                                style={{
                                                    maxWidth: '100%',
                                                    marginTop: '30px',
                                                }}>
                                                {this.renderActiveCallsTable()}
                                            </div>
                                        </Paper>
                                    </Zoom>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        permissions: state.auth.user.permissions,
    };
};

export default connect(mapStateToProps, {
    setOVToken,
    setLoadingOVToken,
    unsetLoadingOVToken,
})(withRouter(withStyles(useStyles)(ActiveCalls)));
