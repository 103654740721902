import React from 'react';
import { connect } from 'react-redux';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {clientDataToggleRecordingConsent} from "../../actions/clientData";

function StartViewConsentCheckbox(props){
    return (
        <FormControlLabel
            style={{marginTop: '10px'}}
            control={
                <Checkbox
                    checked={props.clientData.recordingConsent}
                    onChange={() => {props.clientDataToggleRecordingConsent()}}
                    name="recordingConsent"
                    color="primary" />
            }
            // label="Даю згоду на запис консультації"
            label={
                <div>
                    <span>Даю згоду на запис консультації</span>
                    <span style={{fontWeight: 600}}> та згоден з <a href='https://allo.ua/ua/expert-online' target='_blank'>умовами використання сервiсу</a></span>
                </div>
            }
        />
    );
};

const mapStateToProps = (state) => {
    return {
        clientData: state.clientData
    }
};

export default connect(mapStateToProps, {clientDataToggleRecordingConsent})(StartViewConsentCheckbox);