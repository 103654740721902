import reduxStore from './reduxStore';

class PermissionValidator {

    static getPermissions(){
        return reduxStore.getState().auth.user.permissions;
    }

    static canViewRawRecordings(){
        return PermissionValidator.getPermissions().includes("viewRawRecordings");
    }

    static canCreateOperator(){
        return PermissionValidator.getPermissions().includes("createOperator");
    }

    static canViewOperators(){
        return PermissionValidator.getPermissions().includes("viewOperators");
    }

    static canViewReports(){
        return PermissionValidator.getPermissions().includes("viewReports");
    }

    static canViewCalls(){
        return PermissionValidator.getPermissions().includes("viewCalls");
    }


}

export default PermissionValidator;
