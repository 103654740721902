import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';

const BAR_COLORS = ['#8884d8', '#E33638', '#4CA746'];

class ClientOsChart extends React.Component {
  getTransformedData = (data) => {
    return data.map((item) => {
      return {
        ...item,
        repDate: moment.unix(item.repDate).format('DD.MM'),
      };
    });
  };

  renderChartBody = (state) => {
    const clientOsData = state.data;

    if (state.loading) {
      return (
        <div style={{ margin: '50px', textAlign: 'center' }}>
          <CircularProgress size={35} />
        </div>
      );
    } else if (state.error) {
      return <div className="">Ошибка получения данных</div>;
    } else if (state.data) {
      return (
        <ResponsiveContainer width="100%" height={300}>
          <BarChart
            data={this.getTransformedData(clientOsData.data)}
            margin={{ top: 5, right: 10, bottom: 5, left: -25 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="repDate" />
            <YAxis />
            <Tooltip />
            <Legend />

            {clientOsData?.oSs.map((os, index) => {
              return (
                <Bar
                  dataKey={`oSs.${os.key}`}
                  name={os.title}
                  fill={BAR_COLORS[index]}
                  key={index}
                />
              );
            })}
          </BarChart>
        </ResponsiveContainer>
      );
    }
    return null;
  };

  render() {
    if (this.props.data) {
      return (
        <div className="">
          <h4 style={{ textAlign: 'center' }}>{this.props.title}</h4>
          {this.renderChartBody(this.props.data)}
        </div>
      );
    }
    return null;
  }
}

export default ClientOsChart;
