import React from 'react';
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";

export default class SubmitPopper extends React.Component {
    constructor(props) {
        super(props);

        this.renderMessage = this.renderMessage.bind(this);
    }

    componentDidMount() {
        document.addEventListener("click", () => {
            if (this.props.open) {
                this.props.handleClosePopper();
            }
        })
    }

    renderMessage(message){

        if(typeof message === 'string'){
            return message;
        } else if(Array.isArray(message)){
            return message.map((item, i) => {
                return (<div key={i} style={i !== 0 ? {marginTop: '7px'} : {}}>{item}</div>);
            });
        }
        return '';
    }

    render() {
        return (
            <Popper
                open={this.props.open}
                anchorEl={this.props.anchorEl}
                placement="top"
                style={{marginBottom: '20px', zIndex: '1400'}}
                transition={true}
            >
                <Grow in={true}>
                    <Paper elevation={7}>
                        <div style={{padding: '15px', fontSize: '15px'}}>{this.renderMessage(this.props.message)}</div>
                    </Paper>
                </Grow>
            </Popper>
        );
    }
}
