import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

class SwitchCameraDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            videoDevices: null,
            loading: false,
            error: null
        };

        this.renderVideoCameras = this.renderVideoCameras.bind(this);
        this.setVideoDevicesToLocalState = this.setVideoDevicesToLocalState.bind(this);

    }

    setVideoDevicesToLocalState() {
        this.setState({loading: true});

        this.props.OV.getDevices()
            .then((devices) => {

                const videoDevices = devices.filter((device) => {
                    return device.kind === 'videoinput';
                });

                this.setState({
                    videoDevices: videoDevices, loading: false
                });
            })
            .catch((error) => {
                this.setState({
                    videoDevices: null, loading: false, error: "Ошибка доступа к видеокамерам"
                });
            });
    }

    componentDidMount() {
        window.setTimeout(this.setVideoDevicesToLocalState, 500);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // if (this.props.open && !prevProps.open) {
        //     this.setVideoDevicesToLocalState();
        // }
    }

    renderVideoCameras() {
        if (this.state.loading) {
            return 'loading...';
        } else if (this.state.error) {
            return this.state.error;
        } else if (this.state.videoDevices) {
            return (
                <List>
                    {this.state.videoDevices.map((videoDevice, i) => (
                        <ListItem button onClick={() => {
                            this.props.switchCamera(videoDevice);
                            this.props.closeSwitchCameraDialog();
                        }} key={i}>
                            <ListItemText primary={videoDevice.label}/>
                        </ListItem>
                    ))}
                </List>
            );
        } else {
            return null;
        }
    }

    render() {
        return (
            <div>
                <Dialog onClose={this.props.closeSwitchCameraDialog} open={this.props.open}>
                    <DialogTitle>Выберите камеру</DialogTitle>
                    {this.renderVideoCameras()}
                </Dialog>
            </div>
        );
    }


}

export default SwitchCameraDialog;