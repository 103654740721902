export const setProduct = (product) => {
    return {
        type: 'CLIENT_DATA_PRODUCT_SET',
        payload: product
    };
};

export const clientDataSetClientName = (clientName) => {
    return {
        type: 'CLIENT_DATA_CLIENTNAME_SET',
        clientName: clientName
    };
};

export const clientDataSetClientId = (clientId) => {
    return {
        type: 'CLIENT_DATA_CLIENTID_SET',
        clientId: clientId
    };
};

export const clientDataSetClientQuestion = (clientQuestion) => {
    return {
        type: 'CLIENT_DATA_CLIENTQUESTION_SET',
        payload: clientQuestion
    };
};

export const clientDataSetAuthorizedClient = (newVal) => {
    return {
        type: 'CLIENT_DATA_AUTHORIZED_CLIENT_SET',
        payload: newVal
    };
};

export const clientDataToggleRecordingConsent = () => {
    return {
        type: 'CLIENT_DATA_TOGGLE_RECORDING_CONSENT'
    };
};

export const clientDataUnset = () => {
    return {
        type: 'CLIENT_DATA_UNSET'
    };
};