import React, { useState, useEffect } from 'react';

import customAxios from '../../utils/customAxios';
import apiRoutes from '../../apiRoutes';

//ui
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';

import { withStyles } from '@material-ui/styles';

//icons
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

//styles
import StreamerDialogStyles from './StreamerDialogStyles';

const DialogTitle = withStyles(StreamerDialogStyles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const UpdateStreamerData = (props) => {
  const { openDialog, toggleDialog, updateStreamer, fetchStreamersFromApi, classes } = props;
  const [values, setValues] = useState({
    userName: '',
    displayName: '',
  });

  const [loading, setLoading] = useState(false);

  let buttonText = 'Сохранить';

  const handleDialogClose = () => {
    toggleDialog(false);
  };

  const handleChange = (prop) => (e) => {
    setValues({ ...values, [prop]: e.target.value });
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (loading) return;

    submitToApi();
  };

  const submitToApi = () => {
    setLoading(true);
    customAxios
      .put(apiRoutes.OPERATORS + `/${updateStreamer.id}`, { ...values })
      .then((res) => {
        setLoading(false);
        handleDialogClose();
        fetchStreamersFromApi();
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  if (loading) {
    buttonText = (
      <>
        <CircularProgress size={25} style={{ color: 'white' }} />
        <span style={{ marginLeft: '10px' }}>Сохранить</span>
      </>
    );
  }

  useEffect(() => {
    setValues({
      userName: updateStreamer?.user?.name,
      displayName: updateStreamer?.user?.display_name,
    });
  }, []);

  return (
    <Dialog
      TransitionComponent={Transition}
      fullWidth={true}
      maxWidth="sm"
      open={openDialog}
      onClose={handleDialogClose}>
      <DialogTitle className={classes.title} onClose={handleDialogClose}>
        Редактирование данных стримера
      </DialogTitle>

      <DialogContent>
        <form className={classes.form} noValidate>
          <TextField
            fullWidth
            label="Имя"
            variant="outlined"
            className={classes.textField}
            value={values.userName}
            onChange={handleChange('userName')}
          />
          <TextField
            fullWidth
            label="Отображаемое имя"
            variant="outlined"
            className={classes.textField}
            value={values.displayName}
            onChange={handleChange('displayName')}
          />

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                className={classes.signInButton}
                color="primary"
                fullWidth
                type="submit"
                variant="contained"
                style={{ width: '100%' }}
                disabled={values.userName === '' || values.displayName === ''}
                onClick={submitHandler}>
                {buttonText}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                className={classes.signInButton}
                color="secondary"
                variant="contained"
                style={{ width: '100%' }}
                onClick={handleDialogClose}
                fullWidth>
                Отмена
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(StreamerDialogStyles)(UpdateStreamerData);
