import React from 'react';
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import MaterialDatePicker from "./MaterialDatePicker";
import Button from "@material-ui/core/Button";

export default function(props){
    return (
        <Paper elevation={7} style={{margin: props.margin}}>
            <Grid container alignItems="flex-end" justify="center" style={{marginTop: '20px'}}>

                <Grid item xs={6} md={3} style={{padding: '10px'}}>
                    <MaterialDatePicker
                        label="От"
                        date={props.dateFrom}
                        handleDateChange={(date) => {
                            props.handleDateChange('dateFrom', date)
                        }}
                    />
                </Grid>
                <Grid item xs={6} md={3} style={{padding: '10px'}}>
                    <MaterialDatePicker
                        label="До"
                        date={props.dateTo}
                        handleDateChange={(date) => {
                            props.handleDateChange('dateTo', date)
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={3} style={{padding: '10px'}}>
                    <Button
                        color="primary"
                        fullWidth
                        type="submit"
                        variant="contained"
                        style={{width: '100%', marginBottom: '10px'}}
                        onClick={props.submitHandler}
                    >
                        {props.buttonText ? props.buttonText : 'Обновить'}
                    </Button>
                </Grid>
            </Grid>
        </Paper>
    );
}