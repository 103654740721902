import React from 'react';
import customAxios from "../utils/customAxios";
import apiRoutes from "../apiRoutes";
import {withStyles} from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Zoom from "@material-ui/core/Zoom";
import Paper from "@material-ui/core/Paper";
import materialTableLocalization from "../utils/materialTableLocalization";
import MaterialTable from "material-table";
import moment from 'moment';
import DatePickerBlock from "../utils/DatePickerBlock";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = theme => ({
    root: {},
    grid: {
        height: '100%'
    },
    name: {
        marginTop: theme.spacing(3),
        color: theme.palette.white
    },
    contentContainer: {},
    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    contentBody: {
        marginTop: '50px',
        paddingLeft: '10px',
        paddingRight: '10px'
    }
});

class OperatorCallsReport extends React.Component {
    constructor(props) {
        super(props);


        this.state = {
            dateFrom: moment().subtract(0, 'days').valueOf(),
            dateTo: new Date(),
            loading: false,
            error: null
        };

        this.fetchReportFromApi = this.fetchReportFromApi.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.downloadBlob = this.downloadBlob.bind(this);
        this.fileDownloadHandler = this.fileDownloadHandler.bind(this);
        this.getSubmitButtonText = this.getSubmitButtonText.bind(this);
    }

    componentDidMount() {
        //this.fetchCallsFromApi();
    }

    fetchReportFromApi() {

        if (this.state.loading) return;

        const from = moment(this.state.dateFrom).startOf('day').unix();
        const to = moment(this.state.dateTo).endOf('day').unix();
        const url = `${apiRoutes.OPERATORS_REPORT}/?from=${from}&to=${to}`;

        this.setState({loading: true, error: null});

        customAxios.get(url, {responseType: 'arraybuffer'})
            .then((res) => {
                this.setState({loading: false});
                this.fileDownloadHandler(res);
            })
            .catch((error) => {
                this.setState({loading: false});
                console.log(error);
            });
    }

    handleDateChange(field, val) {
        this.setState({[field]: val});
    }

    downloadBlob(blob, filename) {
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename || 'download';

        // Click handler that releases the object URL after the element has been clicked
        // This is required for one-off downloads of the blob content
        function clickHandler(){
            setTimeout(() => {
                URL.revokeObjectURL(url);
                this.removeEventListener('click', clickHandler);
            }, 150);
        };

        // Add the click event listener on the anchor element
        // Comment out this line if you don't want a one-off download of the blob content
        a.addEventListener('click', clickHandler, false);

        a.click();

        return a;
    }

    fileDownloadHandler(response) {

        const blob = new Blob([response.data],
            {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
        );

        const downloadLink = this.downloadBlob(blob, `report_${moment().format('DD_MM_YYYY__HH_mm')}.xlsx`);

        // downloadLink.title = 'Экспорт отчета';
        // downloadLink.textContent = 'Экспорт отчета в xlsx';
        downloadLink.style.display = 'none';
        document.body.appendChild(downloadLink);
    }

    getSubmitButtonText(){
        if(this.state.loading){
            return (
                <React.Fragment>
                    <div style={{position: 'relative'}}>
                        <CircularProgress size={20}
                                          style={
                                              {
                                                  position: 'absolute',
                                                  left: '-30px',
                                                  top: '2px',
                                                  color: 'white'
                                              }
                                          }/>
                        <span>
                            Загрузить
                        </span>
                    </div>
                </React.Fragment>

            );
        }
        return 'Загрузить'
    }


    render() {

        const {classes} = this.props;

        return (
            <div>
                <div className={classes.root}>
                    <Grid
                        className={classes.grid}
                        container
                    >
                        <Grid
                            className={classes.content}
                            item
                            xs={12}
                        >
                            <div className={classes.content}>
                                <div className={classes.contentBody}>
                                    <Typography
                                        align="center"
                                        gutterBottom
                                        variant="h4"
                                        style={{fontSize: '24px'}}
                                    >
                                        <span style={{color: '#37474f'}}>Отчет по операторам</span>
                                    </Typography>


                                    <DatePickerBlock
                                        handleDateChange={this.handleDateChange}
                                        dateFrom={this.state.dateFrom}
                                        dateTo={this.state.dateTo}
                                        submitHandler={() => {
                                            this.fetchReportFromApi()
                                        }}
                                        buttonText={this.getSubmitButtonText()}
                                    />
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        );
    }

}

export default withStyles(useStyles)(OperatorCallsReport);