import React from 'react';
import {connect} from 'react-redux';

import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import IconButton from "@material-ui/core/IconButton";

import customAxios from "../../utils/customAxios";
import apiRoutes from "../../apiRoutes";
import {clientDataSetAuthorizedClient, clientDataSetClientId, clientDataSetClientName} from "../../actions/clientData";


class StartViewName extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            authorizedClient: false,
            clientName: '',
            loading: false,
            error: null
        };

        this.renderIcon = this.renderIcon.bind(this);
        this.renderIconStyle = this.renderIconStyle.bind(this);
        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.fetchClient = this.fetchClient.bind(this);

    }

    fetchClient(clientId) {

        this.setState({loading: true});

        customAxios.get(apiRoutes.CLIENT + `/${clientId}`)
            .then((res) => {
                console.log(res.data);
                this.setState({
                    authorizedClient: true,
                    clientName: res.data.data.firstname,
                    loading: false
                }, () => {
                    this.props.clientDataSetClientName(this.state.clientName);
                    this.props.clientDataSetAuthorizedClient(this.state.authorizedClient);
                });
            })
            .catch((error) => {
                this.setState({loading: false});
                window.location.href = 'https://allo.ua';
            });
    }

    componentDidMount() {
        if (this.props.clientId) {
            this.fetchClient(this.props.clientId);
            this.props.clientDataSetClientId(this.props.clientId);
        }
    }

    handleFieldChange(event, field) {
        event.persist();

        this.setState((prevState) => {
            return {...prevState, [field]: event.target.value};
        }, () => {
            this.props.clientDataSetClientName(this.state.clientName);
        });
    }

    renderIcon() {
        return (
            <IconButton color="inherit" style={this.renderIconStyle()}>
                <AccountCircleIcon color='primary'/>
            </IconButton>
        );
    }

    renderIconStyle() {
        return {
            position: 'absolute',
            top: '5px',
            left: '-60px',
            backgroundColor: 'rgba(0, 0, 0, 0.05)'
        };
    }

    render() {
        return (
            <div>
                <FormControl style={{width: '100%', margin: '15px 0px', position: 'relative'}}>
                    <TextField
                        fullWidth
                        label={this.props.label}
                        onChange={(e) => this.handleFieldChange(e, 'clientName')}
                        type="text"
                        value={this.state.clientName}
                        style={{width: '100%'}}
                    />
                    {this.renderIcon()}
                </FormControl>
            </div>
        );
    }

}

export default connect(null, {clientDataSetClientName, clientDataSetClientId, clientDataSetAuthorizedClient})(StartViewName);