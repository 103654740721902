import { combineReducers } from 'redux';
import AuthReducer from "./AuthReducer";
import OperatorStatusReducer from "./OperatorStatusReducer";
import OVSessionReducer from "./OVSessionReducer";
import MediaDevicesReducer from "./MediaDevicesReducer";
import ClientDataReducer from "./ClientDataReducer";
import SWReducer from "./SWReducer";
import AppErrorReducer from "./AppErrorReducer";

const appReducer = combineReducers({
    auth: AuthReducer,
    operatorStatus: OperatorStatusReducer,
    OVSession: OVSessionReducer,
    mediaDevicesState: MediaDevicesReducer,
    clientData: ClientDataReducer,
    swData: SWReducer,
    appErrorState: AppErrorReducer
});

const rootReducer = (state, action) => {
    if (action.type === 'AUTH_LOGOUT') {
        state = undefined;
    }

    return appReducer(state, action);
};

export default rootReducer;