export default (state = {
    status: null,
    error: null,
    loading: false
}, action) => {

    switch (action.type) {

        case 'OPERATOR_FETCH_STATUS_START':
            return {...state, error: null, loading: true};

        case 'OPERATOR_FETCH_STATUS_SUCCESS':
            return {...state, loading: false, status: action.payload};

        case 'OPERATOR_FETCH_STATUS_FAIL':
            return {...state, loading: false, error: action.payload };

        case 'OPERATOR_CHANGE_STATUS_START':
            return {...state, error: null, loading: true};

        case 'OPERATOR_CHANGE_STATUS_SUCCESS':
            return {...state, loading: false, status: action.payload};

        case 'OPERATOR_CHANGE_STATUS_FAIL':
            return {...state, loading: false, error: action.payload };

        default:
            return state;

    }
}