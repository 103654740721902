import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {connect} from "react-redux";
import { ThemeProvider } from '@material-ui/styles';
import Routes from "./Routes";
import theme from './theme';
import {authCheckState} from "./actions";
import './app.scss';


class App extends React.Component {

    componentDidMount() {
        this.props.authCheckState();
    }

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Router>
                    <Routes auth={this.props.auth}/>
                </Router>
            </ThemeProvider>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
};

export default connect(mapStateToProps, {authCheckState})(App);