import React from 'react';
import TextField from '@material-ui/core/TextField';
import Zoom from '@material-ui/core/Zoom';
import makeStyles from "@material-ui/core/styles/makeStyles";


const useStyles = makeStyles((theme) => ({
    root: {
        '& label': {
            fontSize: '15px'
        },
    },
}));

function Comment(props) {

    const classes = useStyles();

    if(!props.rating || props.rating === 10){
        return null;
    }
    return (
        <Zoom in={true}>
            <div className="" style={{marginTop: '30px'}}>
                <TextField
                    className={classes.root}
                    multiline
                    label={props.label}
                    rows={3}
                    variant="outlined"
                    size="small"
                    style={{width: '100%', fontSize: '14px', padding: '0px'}}
                    onChange={props.commentChangeHandler}
                />
            </div>
        </Zoom>
    );
}

export default Comment;
