import React from 'react';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import {OpenVidu} from "openvidu-browser";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import {Videocam, VideocamOff} from "@material-ui/icons";
import {setMediaVideo} from "../../actions/mediaDevices";
import {connect} from 'react-redux';

class StartViewVideoSelector extends React.Component {
    constructor(props) {
        super(props);

        this.OV = new OpenVidu();
        this.el = React.createRef();

        this.state = {
            permission: false,
            videoDevices: null,
            loading: false,
            error: null,
            selectedDevice: "",
            selectedDeviceBackup: null,
            disabled: true
        };

        this.videoChangeHandler = this.videoChangeHandler.bind(this);
        this.setVideoDevicesToLocalState = this.setVideoDevicesToLocalState.bind(this);
        this.renderVideoDevices = this.renderVideoDevices.bind(this);
        this.renderIcon = this.renderIcon.bind(this);
        this.renderIconStyle = this.renderIconStyle.bind(this);
        this.toggleState = this.toggleState.bind(this);
    }

    componentDidMount() {
        // navigator.mediaDevices.getUserMedia({
        //     video: true
        // })
        //     .then(() => {
        //         this.setVideoDevicesToLocalState(() => {
        //             this.setState({
        //                 permission: true
        //             }, () => {
        //                 this.props.setMediaVideo(
        //                     {
        //                         permission: this.state.permission
        //                     }
        //                 );
        //             });
        //         });
        //     })
        //     .catch(() => {
        //         console.log('permission denied');
        //     });
    }

    setVideoDevicesToLocalState(callback) {
        this.setState({loading: true});

        this.OV.getDevices()
            .then((devices) => {

                const videoDevices = devices.filter((device) => {
                    return device.kind === 'videoinput';
                });

                this.setState({
                    videoDevices: videoDevices, loading: false
                }, () => {
                    this.props.setMediaVideo({videoDevices: this.state.videoDevices});
                    if (callback) {
                        callback()
                    }
                });
            })
            .catch((error) => {
                this.setState({
                    videoDevices: null, loading: false, error: "Ошибка доступа к видеокамерам"
                });
            });
    }

    toggleState() {

        if (!this.state.permission) {
            navigator.mediaDevices.getUserMedia({
                video: true
            })
                .then(() => {
                    this.setVideoDevicesToLocalState(() => {
                        this.setState({
                            permission: true,
                            disabled: false,
                            selectedDevice: this.state.videoDevices[0].deviceId
                        }, () => {
                            this.props.setMediaVideo(
                                {
                                    permission: this.state.permission,
                                    selectedDevice: this.state.selectedDevice
                                }
                                );
                        });
                    });
                })
                .catch(() => {
                    console.log('permission denied');
                });
            return;
        }

        this.setState((prevState) => {

            let selectedDeviceBackup = prevState.selectedDeviceBackup;
            let selectedDevice = prevState.selectedDevice;

            if (!prevState.disabled) {
                if (prevState.selectedDevice) {
                    selectedDeviceBackup = prevState.selectedDevice;
                    selectedDevice = "";
                }
            } else {
                if (prevState.selectedDeviceBackup) {
                    selectedDevice = prevState.selectedDeviceBackup;
                    selectedDeviceBackup = null;
                } else {
                    selectedDevice = this.state.videoDevices[0].deviceId;
                }
            }

            return {
                ...prevState,
                disabled: !prevState.disabled,
                selectedDevice: selectedDevice,
                selectedDeviceBackup: selectedDeviceBackup
            }
        }, () => {
            this.props.setMediaVideo({selectedDevice: this.state.selectedDevice});
        });
    }

    renderIcon() {
        return (
            <IconButton color="inherit" style={this.renderIconStyle()} onClick={this.toggleState}
            disabled={!this.props.clientData.authorizedClient}
            >
                {this.state.disabled ? <VideocamOff /> : <Videocam color='primary'/>}
            </IconButton>
        );
    }

    renderIconStyle() {
        return {
            position: 'absolute',
            top: '5px',
            left: '-60px',
            backgroundColor: 'rgba(0, 0, 0, 0.05)'
        };
    }

    renderVideoDevices() {
        if (this.state.videoDevices && this.state.videoDevices.length > 0) {

            return this.state.videoDevices.map((videoDevice, i) => (
                <MenuItem key={i} value={videoDevice.deviceId}>
                    {videoDevice.label}
                </MenuItem>
            ));
        }
        return null;
    }

    videoChangeHandler(e) {
        //window.setTimeout(() => {console.log(document.activeElement); document.activeElement.blur();}, 1500);
        // console.log(document.activeElement);
        // console.log(this.el.blur());
        if (e.target.value === '') {
            this.setState({selectedDevice: e.target.value},
                () => {
                    this.props.setMediaVideo({selectedDevice: this.state.selectedDevice});
                    window.setTimeout(() => {
                        this.el.children[0].blur();
                        this.setState({disabled: true});
                    }, 100)
                });
        } else {
            this.setState(
                {selectedDevice: e.target.value, disabled: false},
                () => {
                    this.props.setMediaVideo({selectedDevice: this.state.selectedDevice});
                });
        }
    }

    render() {
        return (
            <FormControl style={{width: '100%', margin: '15px 0px', position: 'relative'}}>
                <InputLabel>Камера</InputLabel>
                <Select
                    value={this.state.selectedDevice}
                    onChange={this.videoChangeHandler}
                    disabled={this.state.disabled}
                    ref={(el) => {
                        this.el = el
                    }}
                >
                    <MenuItem value="">Відключити камеру</MenuItem>
                    {this.renderVideoDevices()}
                </Select>
                {this.renderIcon()}
            </FormControl>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        clientData: state.clientData
    }
};

export default connect(mapStateToProps, {setMediaVideo})(StartViewVideoSelector);