import React from 'react';
import firebase from 'firebase/app';
import {setSWRegistration} from "../actions/sw";
import {connect} from 'react-redux';
import {withRouter} from "react-router";
import customAxios from "./customAxios";
import apiRoutes from "../apiRoutes";

class SWNotification extends React.Component {
    constructor(props) {
        super(props);

        this.messaging = null;

        this.subscribe = this.subscribe.bind(this);
        this.sendTokenToServer = this.sendTokenToServer.bind(this);
        this.isTokenSentToServer = this.isTokenSentToServer.bind(this);
        this.setTokenSentToServer = this.setTokenSentToServer.bind(this);
        this.checkPermission = this.checkPermission.bind(this);
        this.checkNotificationPromise = this.checkNotificationPromise.bind(this);
        this.handleSWNotifications = this.handleSWNotifications.bind(this);
    }

    componentDidMount() {
        this.checkPermission(() => {this.handleSWNotifications()});
    }

    subscribe() {
        this.messaging.requestPermission()
            .then(() =>  {
                // получаем ID устройства
                this.messaging.getToken()
                    .then((currentToken) => {
                        console.log(currentToken);
                        this.sendTokenToServer(currentToken);
                    })
                    .catch(function (err) {
                        console.warn('При получении токена произошла ошибка.', err);
                    });
            })
            .catch(function (err) {
                console.warn('Не удалось получить разрешение на показ уведомлений.', err);
            });
    }

    sendTokenToServer(currentToken) {
        if (!this.isTokenSentToServer(currentToken)) {
            console.log('Отправка токена на сервер...');

            customAxios.post(apiRoutes.SET_FIREBASE_TOKEN, {token: currentToken})
                .then((res) => {
                    this.setTokenSentToServer(currentToken);
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            console.log('Токен уже отправлен на сервер.');
        }
    }

    isTokenSentToServer(currentToken) {
        return window.localStorage.getItem('sentFirebaseMessagingToken') == currentToken;
    }

    setTokenSentToServer(currentToken) {
        window.localStorage.setItem(
            'sentFirebaseMessagingToken',
            currentToken ? currentToken : ''
        );
    }

    checkNotificationPromise() {
        try {
            Notification.requestPermission().then();
        } catch (e) {
            return false;
        }
        return true;
    }

    checkPermission(callback) {

        if (!('Notification' in window)) return;

        if (window.Notification && Notification.permission === "granted"){
            callback();
            return;
        }

        if (this.checkNotificationPromise()) {
            Notification.requestPermission()
                .then((status) => {
                    if (Notification.permission !== status) {
                        Notification.permission = status;
                        callback();
                    }
                })
        } else {
            Notification.requestPermission(function (status) {
                if (Notification.permission !== status) {
                    Notification.permission = status;
                    callback();
                }
            });
        }
    }

    handleSWNotifications(){

        navigator.serviceWorker.register('/firebase-messaging-sw.js')
            .then((registration) => {

            })
            .catch((error) => {
                console.log(error)
            });
    }

    render() {
        return null;
    }
}

const mapStateToProps = (state) => {
    return {
        swData: state.swData
    }
};

export default connect(mapStateToProps, {setSWRegistration})(withRouter(SWNotification));



