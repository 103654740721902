import React from 'react';
import Rating from '@material-ui/lab/Rating';

function StarRating(props) {
    return (
        <Rating
            size="large"
            max={5}
            precision={1}
            style={{color: '#e31837'}}
            value={props.rating}
            onChange={props.ratingChangeHandler}
        />
    );
}

export default StarRating;
