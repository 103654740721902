import customAxios from '../../utils/customAxios';
import apiRoutes from '../../apiRoutes';

export const createOVToken = (sessionId) => {

    return (dispatch) => {

        dispatch({type: 'OV_FETCH_TOKEN_START'});

        const data = sessionId ? {session: sessionId} : {};

        customAxios.post(apiRoutes.TOKENS, data)
            .then((response) => {
                dispatch({
                    type: 'OV_FETCH_TOKEN_SUCCESS',
                    payload: response.data.status
                });
            })
            .catch((error) => {
                dispatch({
                    type: 'OV_FETCH_TOKEN_FAIL',
                    payload: 'Ошибка получения токена openvidu'
                });
            });
    }
};

export const setLoadingOVToken = () => {
    return {
        type: 'OV_SET_LOADING_TOKEN'
    };
};

export const unsetLoadingOVToken = () => {
    return {
        type: 'OV_UNSET_LOADING_TOKEN'
    };
};

export const setOVToken = (token) => {
    return {
        type: 'OV_SET_TOKEN',
        payload: token
    };
};

export const setOVSession = (sessionId) => {
    return {
        type: 'OV_SET_SESSION_ID',
        payload: sessionId
    };
};