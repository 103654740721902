import React from 'react';

export default function StartViewCounterSocial() {
    return (
        <div className="social-box">
            <div className="social-subscribe">
                <h6>Приєднуйся до наших соц. мереж!</h6>
                <ul className="social-subscribe__list">
                    <li className="social-subscribe__list-item">
                        <a
                            className="social-subscribe__fb"
                            href="https://facebook.com/allo"
                            target="_blank">
                            facebook
                        </a>
                    </li>
                    <li className="social-subscribe__list-item">
                        <a
                            className="social-subscribe__inst"
                            href="https://www.instagram.com/allo/"
                            target="_blank">
                            instagram
                        </a>
                    </li>
                    <li className="social-subscribe__list-item">
                        <a
                            className="social-subscribe__tlg"
                            href="https://t.me/allonews"
                            target="_blank">
                            telegram
                        </a>
                    </li>
                    <li className="social-subscribe__list-item">
                        <a
                            className="social-subscribe__you"
                            href="https://www.youtube.com/subscription_center?add_user=allouavideo"
                            target="_blank">
                            youtube
                        </a>
                    </li>
                </ul>
            </div>
            <div className="social-messengers">
                <h6>Звертайся з будь-яких питань!</h6>
                <ul className="social-messengers__list">
                    <li className="social-messengers-item">
                        <a
                            className="social-messengers__fb"
                            href="https://m.me/allo"
                            target="_blank">
                            facebook
                        </a>
                    </li>
                    <li className="social-messengers-item">
                        <a
                            className="social-messengers__viber"
                            href="https://chats.viber.com/allo"
                            target="_blank">
                            viber
                        </a>
                    </li>
                    <li className="social-messengers-item">
                        <a
                            className="social-messengers__tlg"
                            href="https://t.me/allohelp_bot"
                            target="_blank">
                            telegram
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );
}
