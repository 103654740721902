export default (
    state = {
        token: null,
        sessionId: null,
        error: null,
        loading: false,
    },
    action,
) => {
    switch (action.type) {
        case 'OV_FETCH_TOKEN_START':
            return { ...state, error: null, loading: true };

        case 'OV_FETCH_TOKEN_SUCCESS':
            return { ...state, loading: false, token: action.payload };

        case 'OV_FETCH_TOKEN_FAIL':
            return { ...state, loading: false, error: action.payload };

        case 'OV_SET_TOKEN':
            return { ...state, token: action.payload, loading: false, error: null };

        case 'OV_SET_SESSION_ID':
            return { ...state, sessionId: action.payload };

        case 'OV_SET_LOADING_TOKEN':
            return { ...state, loading: true };

        case 'OV_UNSET_LOADING_TOKEN':
            return { ...state, loading: false };

        default:
            return state;
    }
};
