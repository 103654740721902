import React, { Component } from 'react';
import Echo from "laravel-echo";
import Pusher from 'pusher-js';

export default class EchoListenerClient extends Component {
    constructor(props) {
        super(props);

        window.Echo = new Echo({
            broadcaster: 'pusher',
            key: '36d2c9919238247cded4',
            cluster: 'eu'
        });
    }

    render() {
        return null;
    }
}