export default (state = {
    audio: {
        permission: false,
        audioDevices: null,
        selectedDevice: null
    },
    video: {
        permission: false,
        videoDevices: null,
        selectedDevice: null
    },
}, action) => {

    switch (action.type) {

        case 'MEDIA_SET_AUDIO':
            return {...state, audio: {...state.audio, ...action.audio} };

        case 'MEDIA_SET_VIDEO':
            return {...state, video: {...state.video, ...action.video} };

        default:
            return state;

    }
}