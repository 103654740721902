import React from 'react';
import { useSelector } from 'react-redux';
//ui
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
//images
import errorImage1 from '../../images/errorPopups/error_image_1.png';
import errorImage2 from '../../images/errorPopups/error_image_2.png';
import errorImage3 from '../../images/errorPopups/error_image_3.png';
import errorImage4 from '../../images/errorPopups/error_image_4.png';
import errorImage5 from '../../images/errorPopups/error_image_5.png';

import './ErrorView.scss';

export default function ErrorViewPopups() {
    const [open, setErrorPopupOpen] = React.useState(false);
    const clientData = useSelector(({ clientData }) => clientData.product);

    const popupsData = [
        {
            title: 'Самі в шоці,',
            text: 'але інтернет зламався! Вже працюємо над цим :)',
            image: errorImage1,
        },
        {
            title: 'Трясця!',
            text: 'Де поділася сторінка?! Пішли шукати.',
            image: errorImage2,
        },
        {
            title: 'От халепа, щось зламалося!',
            text: 'Аллоїд вже тут,  скоро все полагодить!',
            image: errorImage3,
        },
        {
            title: 'Якась технічна фігня :(',
            text: 'Скоро все відновимо!',
            image: errorImage4,
        },
        {
            title: 'Упс...',
            text: 'Якось воно само так вийшло :(',
            image: errorImage5,
        },
    ];

    const randomItem = Math.floor(Math.random() * popupsData.length);

    React.useEffect(() => {
        handleErrorPopupOpen();
    }, []);

    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="down" ref={ref} {...props} />;
    });

    const handleErrorPopupOpen = () => {
        setErrorPopupOpen(true);
    };

    const handleErrorPopupClose = () => {
        setErrorPopupOpen(false);
    };

    return (
        <Dialog open={open} TransitionComponent={Transition} onClose={handleErrorPopupClose} className="error-popup">
            <div className="error-popup__close">
                <IconButton aria-label="close" color="primary" onClick={handleErrorPopupClose}>
                    <CloseIcon />
                </IconButton>
            </div>
            <DialogContent>
                <Grid container className="error-popup__container">
                    <Grid className="error-popup__item" item xs={12} sm={5}>
                        <img src={popupsData[randomItem].image} />
                    </Grid>
                    <Grid className="error-popup__item" item xs={12} sm={7}>
                        <Typography variant="h2" className="error-popup__title">
                            {popupsData[randomItem].title}
                        </Typography>

                        <Typography variant="body1" className="error-popup__text">
                            {popupsData[randomItem].text}
                        </Typography>

                        <div className="error-popup__back">
                            Ви можете <a href={clientData && clientData.url}>повернутись</a>, або спробувати ще раз
                        </div>
                        <Button className="error-popup__btn" color="primary" variant="contained">
                            Спробувати знову
                        </Button>
                    </Grid>
                </Grid>
                <div className="error-popup__decor"></div>
            </DialogContent>
        </Dialog>
    );
}
