import React from 'react';
import moment from "moment";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Zoom from "@material-ui/core/Zoom";
import Paper from "@material-ui/core/Paper";
import materialTableLocalization from "../../utils/materialTableLocalization";
import {withStyles} from "@material-ui/styles";
import MaterialTable from "material-table";

import customAxios from "../../utils/customAxios";
import apiRoutes from "../../apiRoutes";


const useStyles = theme => ({
    root: {},
    grid: {
        height: '100%'
    },
    name: {
        marginTop: theme.spacing(3),
        color: theme.palette.white
    },
    contentContainer: {},
    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    contentBody: {
        marginTop: '50px',
        paddingLeft: '10px',
        paddingRight: '10px'
    }
});

class StreamVideoArchiveView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            recordings: [],
            loading: false,
            error: null
        };

        this.fetchRecordingsFromApi = this.fetchRecordingsFromApi.bind(this);
        this.renderRecordingsTable = this.renderRecordingsTable.bind(this);
        this.getTransformedRecordingsFromState = this.getTransformedRecordingsFromState.bind(this);
        this.handleLinkClick = this.handleLinkClick.bind(this);
        this.handleDownloadClick = this.handleDownloadClick.bind(this);
    }

    fetchRecordingsFromApi() {

        this.setState({loading: true});

        customAxios.get(apiRoutes.RECORDINGS)
            .then((res) => {
                this.setState({
                    recordings: res.data?.items ?? [],
                    loading: false,
                    error: null
                });
            })
            .catch((error) => {
                console.log(error);
                this.setState({loading: false, error: 'Ошибка получения записей'});
            });
    }

    getTransformedRecordingsFromState() {
        const recordings = this.state.recordings.map((recording) => {
            const modifiedCreatedAt = moment(recording.createdAt).format('DD.MM.YYYY HH:mm:ss');
            const modifiedDuration = parseInt(recording.duration);

            return {
                ...recording,
                createdAtModified: modifiedCreatedAt,
                duration: modifiedDuration
            };
        });

        recordings.sort(function(a,b){
            return new Date(b.createdAt) - new Date(a.createdAt);
        });

        return recordings;
    }

    renderRecordingsTable() {

        if (this.state.error) {
            return (
                <div style={{textAlign: 'center', marginTop: '100px', marginBottom: '100px', padding: '50px 10px'}}>
                    {this.state.error}
                </div>
            );
        } else {
            return (
                <MaterialTable
                    isLoading={this.state.loading}
                    columns={[
                        {title: 'ID сессии', field: 'sessionId'},
                        // {title: 'Имя', field: 'user.name'},
                        {title: 'Продолжительность, сек', field: 'duration'},
                        {title: 'Статус', field: 'status'},
                        {title: 'Время создания', field: 'createdAtModified'}
                    ]}
                    data={this.getTransformedRecordingsFromState()}
                    actions={[
                        rowData => ({
                            icon: 'save',
                            tooltip: 'Скачать архив с записью',
                            onClick: (event, rowData) => {
                                this.handleDownloadClick(rowData);
                            },
                            disabled: rowData.status !== 'ready'
                        }),
                        rowData => ({
                            icon: 'link',
                            tooltip: 'Просмотр записи 1',
                            onClick: (event, rowData) => {
                                this.handleLinkClick(rowData, true);
                            },
                            disabled: !!!rowData.recordings
                        }),
                        rowData => ({
                            icon: 'link',
                            tooltip: 'Просмотр записи 2',
                            onClick: (event, rowData) => {
                                this.handleLinkClick(rowData, false);
                            },
                            disabled: !!!rowData.recordings
                        })
                    ]}
                    localization={materialTableLocalization}
                    title=""
                    options={{
                        pageSize: 200,
                        pageSizeOptions: [20, 50, 100, 200],
                        emptyRowsWhenPaging: false,
                        exportButton: false,
                        sorting: false
                    }}
                />
            );
        }
    }

    handleDownloadClick(recording) {
        const win = window.open(recording.url, '_blank');
        win.focus();
    }

    handleLinkClick(recording, first) {
        if (!recording.recordings) return;

        const linkUrl = first ?
            recording.recordings[0].url :
            recording.recordings[1].url;

        const win = window.open(linkUrl, '_blank');
        win.focus();
    }

    componentDidMount() {
        this.fetchRecordingsFromApi();
    }

    render() {

        const {classes} = this.props;

        return (
            <div>
                <div className={classes.root}>
                    <Grid
                        className={classes.grid}
                        container
                    >
                        <Grid
                            className={classes.content}
                            item
                            xs={12}
                        >
                            <div className={classes.content}>
                                <div className={classes.contentBody}>
                                    <Typography
                                        align="center"
                                        gutterBottom
                                        variant="h4"
                                        style={{fontSize: '24px'}}
                                    >
                                        <span style={{color: '#37474f'}}>Записи звонков</span>
                                    </Typography>

                                    <Zoom in={true}>
                                        <Paper elevation={7}>
                                            <div style={{maxWidth: '100%', marginTop: '30px'}}>
                                                {this.renderRecordingsTable()}
                                            </div>
                                        </Paper>
                                    </Zoom>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        );
    }
}

export default withStyles(useStyles)(StreamVideoArchiveView);