export default (
    state = {
        callError: false,
    },
    action,
) => {
    switch (action.type) {
        case 'APP_ERROR_SET_CALL_ERROR':
            return { ...state, callError: action.payload };

        default:
            return state;
    }
};
