import axios from 'axios';
import reduxStore from './reduxStore';
import {logout} from '../actions';

const customAxios = () => {
    const defaultOptions = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    let instance = axios.create(defaultOptions);

    instance.interceptors.request.use(function (request) {
        let token = reduxStore.getState().auth.token;
        if (token === null) {
            token = localStorage.getItem('token');
        }
        request.headers.Authorization = token ? `Bearer ${token}` : '';

        return request;
    });

    instance.interceptors.response.use(response => response, (error) => {
        if (error.response.data && error.response.data.message === "Token has expired") {
            reduxStore.dispatch(logout());
            return;
        }

        return Promise.reject({...error});
    });

    return instance;
};

export default customAxios();