import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Avatar, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import user_logo from '../../../../../../images/user.svg';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  avatar: {
    width: 60,
    height: 60
  },
  name: {
    marginTop: theme.spacing(1)
  }
}));

const Profile = props => {
  const { className, auth, ...rest } = props;

  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      {/*<Avatar*/}
      {/*alt="Person"*/}
      {/*className={classes.avatar}*/}
      {/*component={RouterLink}*/}
      {/*src={user.avatar}*/}
      {/*to="/settings"*/}
      {/*/>*/}

      <img
        alt="Logo"
        src={user_logo}
        style={{width: '80px'}}
      />
      <Typography
        className={classes.name}
        variant="h4"
      >
        {auth.user.name}
      </Typography>
      {/*<Typography variant="body2">{user.bio}</Typography>*/}
    </div>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  };
};

export default connect(mapStateToProps)(Profile);
