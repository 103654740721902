import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Badge, Hidden, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
import { connect } from 'react-redux';
import { logout } from '../../../../actions';
import allo_logo from '../../../../images/logo_allo_white.svg';
import OperatorStatus from '../../../../components/OperatorStatus/OperatorStatus';

const useStyles = makeStyles((theme) => ({
    root: {
        boxShadow: 'none',
    },
    flexGrow: {
        flexGrow: 1,
    },
    signOutButton: {
        marginLeft: theme.spacing(1),
    },
}));

const Topbar = (props) => {
    const { className, onSidebarOpen, ...rest } = props;

    const classes = useStyles();

    const [notifications] = useState([]);

    return (
        <AppBar {...rest} className={clsx(classes.root, className)}>
            <Toolbar>
                <RouterLink to="/">
                    <img alt="Logo" src={allo_logo} style={{ width: '110px' }} />
                </RouterLink>
                <div className={classes.flexGrow} />
                {/*<Hidden mdDown>*/}
                {props.auth.user.operator && <OperatorStatus />}
                <IconButton color="inherit">
                    <Badge badgeContent={notifications.length} color="primary" variant="dot">
                        <NotificationsIcon />
                    </Badge>
                </IconButton>
                {/*<IconButton*/}
                {/*className={classes.signOutButton}*/}
                {/*color="inherit"*/}
                {/*onClick={props.logout}*/}
                {/*>*/}
                {/*<InputIcon/>*/}
                {/*</IconButton>*/}
                {/*</Hidden>*/}
                <Hidden lgUp>
                    <IconButton color="inherit" onClick={onSidebarOpen}>
                        <MenuIcon />
                    </IconButton>
                </Hidden>
            </Toolbar>
        </AppBar>
    );
};

Topbar.propTypes = {
    className: PropTypes.string,
    onSidebarOpen: PropTypes.func,
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};

export default connect(mapStateToProps, { logout })(Topbar);
